@charset "UTF-8";
/* Der Include Pfad zu Bootstrap-Dateien wird automatisch gesetzt. */
:root {
  --blue: #003da5;
  --indigo: #6610f2;
  --purple: #582c83;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fe5000;
  --yellow: #ffc72c;
  --green: #c4d600;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #999999;
  --gray-dark: #3d3d3d;
  --primary: #ffc72c;
  --secondary: #fff;
  --success: #c4d600;
  --info: #17a2b8;
  --warning: #ffc72c;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #3d3d3d;
  --purple: #582c83;
  --purple-80: #7a569b;
  --black: #000;
  --grau: #6f6f6e;
  --hellgrau: #bbbcbc;
  --blau: #003da5;
  --orange: #fe5000;
  --gruen: #c4d600;
  --tuerkis: #64ccc9;
  --gelb: #ffc72c;
  --gray-200: #f1f1f1;
  --gray-400: #cccccc;
  --gray-600: #999999;
  --gray-700: #727272;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1240px;
  --font-family-sans-serif: Corbel, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Corbel, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.625rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #e7b00e;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #9f790a;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #999999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.625rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 1.6rem; }

h2, .h2 {
  font-size: 1.6rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 700; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999999; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 87.5%;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1240px) {
    .container {
      max-width: 1212px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1240px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f1f1f1; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f1f1; }
  .table tbody + tbody {
    border-top: 2px solid #f1f1f1; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f1f1f1; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f1f1f1; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffefc4; }

.table-hover .table-primary:hover {
  background-color: #ffe8ab; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffe8ab; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #eef4b8; }

.table-hover .table-success:hover {
  background-color: #e9f1a2; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #e9f1a2; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefc4; }

.table-hover .table-warning:hover {
  background-color: #ffe8ab; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8ab; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c9c9; }

.table-hover .table-dark:hover {
  background-color: #bcbcbc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbcbc; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d0c4dc; }

.table-hover .table-purple:hover {
  background-color: #c3b4d3; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c3b4d3; }

.table-purple-80,
.table-purple-80 > th,
.table-purple-80 > td {
  background-color: #dad0e3; }

.table-hover .table-purple-80:hover {
  background-color: #cdc0d9; }
  .table-hover .table-purple-80:hover > td,
  .table-hover .table-purple-80:hover > th {
    background-color: #cdc0d9; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-grau,
.table-grau > th,
.table-grau > td {
  background-color: #d7d7d6; }

.table-hover .table-grau:hover {
  background-color: #cacac9; }
  .table-hover .table-grau:hover > td,
  .table-hover .table-grau:hover > th {
    background-color: #cacac9; }

.table-hellgrau,
.table-hellgrau > th,
.table-hellgrau > td {
  background-color: #ececec; }

.table-hover .table-hellgrau:hover {
  background-color: #dfdfdf; }
  .table-hover .table-hellgrau:hover > td,
  .table-hover .table-hellgrau:hover > th {
    background-color: #dfdfdf; }

.table-blau,
.table-blau > th,
.table-blau > td {
  background-color: #b8c9e6; }

.table-hover .table-blau:hover {
  background-color: #a5bbdf; }
  .table-hover .table-blau:hover > td,
  .table-hover .table-blau:hover > th {
    background-color: #a5bbdf; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffceb8; }

.table-hover .table-orange:hover {
  background-color: #ffbc9f; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffbc9f; }

.table-gruen,
.table-gruen > th,
.table-gruen > td {
  background-color: #eef4b8; }

.table-hover .table-gruen:hover {
  background-color: #e9f1a2; }
  .table-hover .table-gruen:hover > td,
  .table-hover .table-gruen:hover > th {
    background-color: #e9f1a2; }

.table-tuerkis,
.table-tuerkis > th,
.table-tuerkis > td {
  background-color: #d4f1f0; }

.table-hover .table-tuerkis:hover {
  background-color: #c1ebe9; }
  .table-hover .table-tuerkis:hover > td,
  .table-hover .table-tuerkis:hover > th {
    background-color: #c1ebe9; }

.table-gelb,
.table-gelb > th,
.table-gelb > td {
  background-color: #ffefc4; }

.table-hover .table-gelb:hover {
  background-color: #ffe8ab; }
  .table-hover .table-gelb:hover > td,
  .table-hover .table-gelb:hover > th {
    background-color: #ffe8ab; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #fbfbfb; }

.table-hover .table-gray-200:hover {
  background-color: #eeeeee; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #eeeeee; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f1f1; }

.table-hover .table-gray-400:hover {
  background-color: #e4e4e4; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #e4e4e4; }

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #e2e2e2; }

.table-hover .table-gray-600:hover {
  background-color: #d5d5d5; }
  .table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #d5d5d5; }

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #d8d8d8; }

.table-hover .table-gray-700:hover {
  background-color: #cbcbcb; }
  .table-hover .table-gray-700:hover > td,
  .table-hover .table-gray-700:hover > th {
    background-color: #cbcbcb; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #727272;
  background-color: #f1f1f1;
  border-color: #f1f1f1; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm.table-bordered {
      border: 0; } }

@media (max-width: 767px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md.table-bordered {
      border: 0; } }

@media (max-width: 991px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg.table-bordered {
      border: 0; } }

@media (max-width: 1239px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl.table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive.table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1.111;
  color: #727272;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 0 solid #cccccc;
  border-radius: 0;
  box-shadow: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #727272;
    background-color: #fff;
    border-color: #ffe9ac;
    outline: none;
    box-shadow: 0, 0 0 0 0 rgba(255, 199, 44, 0.25); }
  .form-control::placeholder {
    color: #999999;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f1f1f1;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(3.361rem + 0); }

select.form-control:focus::-ms-value {
  color: #727272;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(1.125rem + 0);
  padding-bottom: calc(1.125rem + 0);
  margin-bottom: 0;
  line-height: 1.111; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 0);
  padding-bottom: calc(0.5rem + 0);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 0);
  padding-bottom: calc(0.25rem + 0);
  font-size: 0.875rem;
  line-height: 1.111; }

.col-form-legend {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-plaintext {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  margin-bottom: 0;
  line-height: 1.111;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.111;
  border-radius: 0; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.472125rem + 0); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 0); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #999999; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }

.valid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #c4d600; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(196, 214, 0, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #c4d600; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 214, 0, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #c4d600; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(196, 214, 0, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #c4d600; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #c4d600; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 214, 0, 0.25); }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0 solid transparent;
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  line-height: 1.111;
  border-radius: 0.2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65;
    box-shadow: none; }
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none;
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.25), 0 3px 7px rgba(0, 0, 0, 0.35); }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #111;
  background-color: #ffc72c;
  border-color: #ffc72c;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-primary:hover {
    color: #111;
    background-color: #ffbd06;
    border-color: #f8b600; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #111;
    background-color: #f8b600;
    border-color: #ebad00;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-secondary {
  color: #111;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-secondary:hover {
    color: #111;
    background-color: #ececec;
    border-color: #e6e5e5; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #fff;
    border-color: #fff; }
  .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #111;
    background-color: #e6e5e5;
    border-color: #dfdfdf;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-success {
  color: #111;
  background-color: #c4d600;
  border-color: #c4d600;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-success:hover {
    color: #111;
    background-color: #a1b000;
    border-color: #95a300; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(196, 214, 0, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #c4d600;
    border-color: #c4d600; }
  .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #95a300;
    border-color: #8a9600;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(196, 214, 0, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #111;
  background-color: #ffc72c;
  border-color: #ffc72c;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-warning:hover {
    color: #111;
    background-color: #ffbd06;
    border-color: #f8b600; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #111;
    background-color: #f8b600;
    border-color: #ebad00;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #111;
    background-color: #dae0e5;
    border-color: #d3d9df;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-dark:hover {
    color: #fff;
    background-color: #2a2a2a;
    border-color: #242323; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(61, 61, 61, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #3d3d3d;
    border-color: #3d3d3d; }
  .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #242323;
    border-color: #1d1d1d;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(61, 61, 61, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #582c83;
  border-color: #582c83;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-purple:hover {
    color: #fff;
    background-color: #452266;
    border-color: #3e1f5d; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(88, 44, 131, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    background-color: #582c83;
    border-color: #582c83; }
  .btn-purple:not([disabled]):not(.disabled):active, .btn-purple:not([disabled]):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #3e1f5d;
    border-color: #381c53;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(88, 44, 131, 0.5); }

.btn-purple-80 {
  color: #fff;
  background-color: #7a569b;
  border-color: #7a569b;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-purple-80:hover {
    color: #fff;
    background-color: #674882;
    border-color: #60447a; }
  .btn-purple-80:focus, .btn-purple-80.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(122, 86, 155, 0.5); }
  .btn-purple-80.disabled, .btn-purple-80:disabled {
    background-color: #7a569b;
    border-color: #7a569b; }
  .btn-purple-80:not([disabled]):not(.disabled):active, .btn-purple-80:not([disabled]):not(.disabled).active,
  .show > .btn-purple-80.dropdown-toggle {
    color: #fff;
    background-color: #60447a;
    border-color: #5a3f72;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(122, 86, 155, 0.5); }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(0, 0, 0, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    background-color: #000;
    border-color: #000; }
  .btn-black:not([disabled]):not(.disabled):active, .btn-black:not([disabled]):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(0, 0, 0, 0.5); }

.btn-grau {
  color: #fff;
  background-color: #6f6f6e;
  border-color: #6f6f6e;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-grau:hover {
    color: #fff;
    background-color: #5c5c5b;
    border-color: #555555; }
  .btn-grau:focus, .btn-grau.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(111, 111, 110, 0.5); }
  .btn-grau.disabled, .btn-grau:disabled {
    background-color: #6f6f6e;
    border-color: #6f6f6e; }
  .btn-grau:not([disabled]):not(.disabled):active, .btn-grau:not([disabled]):not(.disabled).active,
  .show > .btn-grau.dropdown-toggle {
    color: #fff;
    background-color: #555555;
    border-color: #4f4f4e;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(111, 111, 110, 0.5); }

.btn-hellgrau {
  color: #111;
  background-color: #bbbcbc;
  border-color: #bbbcbc;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-hellgrau:hover {
    color: #111;
    background-color: #a8a9a9;
    border-color: #a1a3a3; }
  .btn-hellgrau:focus, .btn-hellgrau.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(187, 188, 188, 0.5); }
  .btn-hellgrau.disabled, .btn-hellgrau:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc; }
  .btn-hellgrau:not([disabled]):not(.disabled):active, .btn-hellgrau:not([disabled]):not(.disabled).active,
  .show > .btn-hellgrau.dropdown-toggle {
    color: #111;
    background-color: #a1a3a3;
    border-color: #9b9c9c;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(187, 188, 188, 0.5); }

.btn-blau {
  color: #fff;
  background-color: #003da5;
  border-color: #003da5;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-blau:hover {
    color: #fff;
    background-color: #002f7f;
    border-color: #002a72; }
  .btn-blau:focus, .btn-blau.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(0, 61, 165, 0.5); }
  .btn-blau.disabled, .btn-blau:disabled {
    background-color: #003da5;
    border-color: #003da5; }
  .btn-blau:not([disabled]):not(.disabled):active, .btn-blau:not([disabled]):not(.disabled).active,
  .show > .btn-blau.dropdown-toggle {
    color: #fff;
    background-color: #002a72;
    border-color: #002565;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(0, 61, 165, 0.5); }

.btn-orange {
  color: #fff;
  background-color: #fe5000;
  border-color: #fe5000;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-orange:hover {
    color: #fff;
    background-color: #d84400;
    border-color: #cb4000; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(254, 80, 0, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    background-color: #fe5000;
    border-color: #fe5000; }
  .btn-orange:not([disabled]):not(.disabled):active, .btn-orange:not([disabled]):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #cb4000;
    border-color: #be3c00;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(254, 80, 0, 0.5); }

.btn-gruen {
  color: #111;
  background-color: #c4d600;
  border-color: #c4d600;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gruen:hover {
    color: #111;
    background-color: #a1b000;
    border-color: #95a300; }
  .btn-gruen:focus, .btn-gruen.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(196, 214, 0, 0.5); }
  .btn-gruen.disabled, .btn-gruen:disabled {
    background-color: #c4d600;
    border-color: #c4d600; }
  .btn-gruen:not([disabled]):not(.disabled):active, .btn-gruen:not([disabled]):not(.disabled).active,
  .show > .btn-gruen.dropdown-toggle {
    color: #fff;
    background-color: #95a300;
    border-color: #8a9600;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(196, 214, 0, 0.5); }

.btn-tuerkis {
  color: #111;
  background-color: #64ccc9;
  border-color: #64ccc9;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-tuerkis:hover {
    color: #111;
    background-color: #47c3bf;
    border-color: #3fbebb; }
  .btn-tuerkis:focus, .btn-tuerkis.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(100, 204, 201, 0.5); }
  .btn-tuerkis.disabled, .btn-tuerkis:disabled {
    background-color: #64ccc9;
    border-color: #64ccc9; }
  .btn-tuerkis:not([disabled]):not(.disabled):active, .btn-tuerkis:not([disabled]):not(.disabled).active,
  .show > .btn-tuerkis.dropdown-toggle {
    color: #111;
    background-color: #3fbebb;
    border-color: #3bb5b1;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(100, 204, 201, 0.5); }

.btn-gelb {
  color: #111;
  background-color: #ffc72c;
  border-color: #ffc72c;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gelb:hover {
    color: #111;
    background-color: #ffbd06;
    border-color: #f8b600; }
  .btn-gelb:focus, .btn-gelb.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-gelb.disabled, .btn-gelb:disabled {
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-gelb:not([disabled]):not(.disabled):active, .btn-gelb:not([disabled]):not(.disabled).active,
  .show > .btn-gelb.dropdown-toggle {
    color: #111;
    background-color: #f8b600;
    border-color: #ebad00;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-gray-200 {
  color: #111;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gray-200:hover {
    color: #111;
    background-color: #dedede;
    border-color: #d8d7d7; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(241, 241, 241, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
  .btn-gray-200:not([disabled]):not(.disabled):active, .btn-gray-200:not([disabled]):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #111;
    background-color: #d8d7d7;
    border-color: #d1d1d1;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(241, 241, 241, 0.5); }

.btn-gray-400 {
  color: #111;
  background-color: #cccccc;
  border-color: #cccccc;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gray-400:hover {
    color: #111;
    background-color: #b9b9b9;
    border-color: #b3b2b2; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(204, 204, 204, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-gray-400:not([disabled]):not(.disabled):active, .btn-gray-400:not([disabled]):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #111;
    background-color: #b3b2b2;
    border-color: #acacac;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(204, 204, 204, 0.5); }

.btn-gray-600 {
  color: #111;
  background-color: #999999;
  border-color: #999999;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #868686;
    border-color: #807f7f; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(153, 153, 153, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    background-color: #999999;
    border-color: #999999; }
  .btn-gray-600:not([disabled]):not(.disabled):active, .btn-gray-600:not([disabled]):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #807f7f;
    border-color: #797979;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(153, 153, 153, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #727272;
  border-color: #727272;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-gray-700:hover {
    color: #fff;
    background-color: #5f5f5f;
    border-color: #595858; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(114, 114, 114, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    background-color: #727272;
    border-color: #727272; }
  .btn-gray-700:not([disabled]):not(.disabled):active, .btn-gray-700:not([disabled]):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #595858;
    border-color: #525252;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35), 0 0 0 0 rgba(114, 114, 114, 0.5); }

.btn-outline-primary {
  color: #ffc72c;
  background-color: transparent;
  background-image: none;
  border-color: #ffc72c; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ffc72c;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c;
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-outline-secondary {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-success {
  color: #c4d600;
  background-color: transparent;
  background-image: none;
  border-color: #c4d600; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #c4d600;
    border-color: #c4d600; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(196, 214, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #c4d600;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #c4d600;
    border-color: #c4d600;
    box-shadow: 0 0 0 0 rgba(196, 214, 0, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc72c;
  background-color: transparent;
  background-image: none;
  border-color: #ffc72c; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc72c;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c;
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #3d3d3d;
  background-color: transparent;
  background-image: none;
  border-color: #3d3d3d; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(61, 61, 61, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3d3d3d;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3d3d3d;
    border-color: #3d3d3d;
    box-shadow: 0 0 0 0 rgba(61, 61, 61, 0.5); }

.btn-outline-purple {
  color: #582c83;
  background-color: transparent;
  background-image: none;
  border-color: #582c83; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #582c83;
    border-color: #582c83; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0 rgba(88, 44, 131, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #582c83;
    background-color: transparent; }
  .btn-outline-purple:not([disabled]):not(.disabled):active, .btn-outline-purple:not([disabled]):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #582c83;
    border-color: #582c83;
    box-shadow: 0 0 0 0 rgba(88, 44, 131, 0.5); }

.btn-outline-purple-80 {
  color: #7a569b;
  background-color: transparent;
  background-image: none;
  border-color: #7a569b; }
  .btn-outline-purple-80:hover {
    color: #fff;
    background-color: #7a569b;
    border-color: #7a569b; }
  .btn-outline-purple-80:focus, .btn-outline-purple-80.focus {
    box-shadow: 0 0 0 0 rgba(122, 86, 155, 0.5); }
  .btn-outline-purple-80.disabled, .btn-outline-purple-80:disabled {
    color: #7a569b;
    background-color: transparent; }
  .btn-outline-purple-80:not([disabled]):not(.disabled):active, .btn-outline-purple-80:not([disabled]):not(.disabled).active,
  .show > .btn-outline-purple-80.dropdown-toggle {
    color: #fff;
    background-color: #7a569b;
    border-color: #7a569b;
    box-shadow: 0 0 0 0 rgba(122, 86, 155, 0.5); }

.btn-outline-black {
  color: #000;
  background-color: transparent;
  background-image: none;
  border-color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-black:not([disabled]):not(.disabled):active, .btn-outline-black:not([disabled]):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

.btn-outline-grau {
  color: #6f6f6e;
  background-color: transparent;
  background-image: none;
  border-color: #6f6f6e; }
  .btn-outline-grau:hover {
    color: #fff;
    background-color: #6f6f6e;
    border-color: #6f6f6e; }
  .btn-outline-grau:focus, .btn-outline-grau.focus {
    box-shadow: 0 0 0 0 rgba(111, 111, 110, 0.5); }
  .btn-outline-grau.disabled, .btn-outline-grau:disabled {
    color: #6f6f6e;
    background-color: transparent; }
  .btn-outline-grau:not([disabled]):not(.disabled):active, .btn-outline-grau:not([disabled]):not(.disabled).active,
  .show > .btn-outline-grau.dropdown-toggle {
    color: #fff;
    background-color: #6f6f6e;
    border-color: #6f6f6e;
    box-shadow: 0 0 0 0 rgba(111, 111, 110, 0.5); }

.btn-outline-hellgrau {
  color: #bbbcbc;
  background-color: transparent;
  background-image: none;
  border-color: #bbbcbc; }
  .btn-outline-hellgrau:hover {
    color: #fff;
    background-color: #bbbcbc;
    border-color: #bbbcbc; }
  .btn-outline-hellgrau:focus, .btn-outline-hellgrau.focus {
    box-shadow: 0 0 0 0 rgba(187, 188, 188, 0.5); }
  .btn-outline-hellgrau.disabled, .btn-outline-hellgrau:disabled {
    color: #bbbcbc;
    background-color: transparent; }
  .btn-outline-hellgrau:not([disabled]):not(.disabled):active, .btn-outline-hellgrau:not([disabled]):not(.disabled).active,
  .show > .btn-outline-hellgrau.dropdown-toggle {
    color: #fff;
    background-color: #bbbcbc;
    border-color: #bbbcbc;
    box-shadow: 0 0 0 0 rgba(187, 188, 188, 0.5); }

.btn-outline-blau {
  color: #003da5;
  background-color: transparent;
  background-image: none;
  border-color: #003da5; }
  .btn-outline-blau:hover {
    color: #fff;
    background-color: #003da5;
    border-color: #003da5; }
  .btn-outline-blau:focus, .btn-outline-blau.focus {
    box-shadow: 0 0 0 0 rgba(0, 61, 165, 0.5); }
  .btn-outline-blau.disabled, .btn-outline-blau:disabled {
    color: #003da5;
    background-color: transparent; }
  .btn-outline-blau:not([disabled]):not(.disabled):active, .btn-outline-blau:not([disabled]):not(.disabled).active,
  .show > .btn-outline-blau.dropdown-toggle {
    color: #fff;
    background-color: #003da5;
    border-color: #003da5;
    box-shadow: 0 0 0 0 rgba(0, 61, 165, 0.5); }

.btn-outline-orange {
  color: #fe5000;
  background-color: transparent;
  background-image: none;
  border-color: #fe5000; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #fe5000;
    border-color: #fe5000; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0 rgba(254, 80, 0, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #fe5000;
    background-color: transparent; }
  .btn-outline-orange:not([disabled]):not(.disabled):active, .btn-outline-orange:not([disabled]):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #fe5000;
    border-color: #fe5000;
    box-shadow: 0 0 0 0 rgba(254, 80, 0, 0.5); }

.btn-outline-gruen {
  color: #c4d600;
  background-color: transparent;
  background-image: none;
  border-color: #c4d600; }
  .btn-outline-gruen:hover {
    color: #fff;
    background-color: #c4d600;
    border-color: #c4d600; }
  .btn-outline-gruen:focus, .btn-outline-gruen.focus {
    box-shadow: 0 0 0 0 rgba(196, 214, 0, 0.5); }
  .btn-outline-gruen.disabled, .btn-outline-gruen:disabled {
    color: #c4d600;
    background-color: transparent; }
  .btn-outline-gruen:not([disabled]):not(.disabled):active, .btn-outline-gruen:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gruen.dropdown-toggle {
    color: #fff;
    background-color: #c4d600;
    border-color: #c4d600;
    box-shadow: 0 0 0 0 rgba(196, 214, 0, 0.5); }

.btn-outline-tuerkis {
  color: #64ccc9;
  background-color: transparent;
  background-image: none;
  border-color: #64ccc9; }
  .btn-outline-tuerkis:hover {
    color: #fff;
    background-color: #64ccc9;
    border-color: #64ccc9; }
  .btn-outline-tuerkis:focus, .btn-outline-tuerkis.focus {
    box-shadow: 0 0 0 0 rgba(100, 204, 201, 0.5); }
  .btn-outline-tuerkis.disabled, .btn-outline-tuerkis:disabled {
    color: #64ccc9;
    background-color: transparent; }
  .btn-outline-tuerkis:not([disabled]):not(.disabled):active, .btn-outline-tuerkis:not([disabled]):not(.disabled).active,
  .show > .btn-outline-tuerkis.dropdown-toggle {
    color: #fff;
    background-color: #64ccc9;
    border-color: #64ccc9;
    box-shadow: 0 0 0 0 rgba(100, 204, 201, 0.5); }

.btn-outline-gelb {
  color: #ffc72c;
  background-color: transparent;
  background-image: none;
  border-color: #ffc72c; }
  .btn-outline-gelb:hover {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c; }
  .btn-outline-gelb:focus, .btn-outline-gelb.focus {
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }
  .btn-outline-gelb.disabled, .btn-outline-gelb:disabled {
    color: #ffc72c;
    background-color: transparent; }
  .btn-outline-gelb:not([disabled]):not(.disabled):active, .btn-outline-gelb:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gelb.dropdown-toggle {
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c;
    box-shadow: 0 0 0 0 rgba(255, 199, 44, 0.5); }

.btn-outline-gray-200 {
  color: #f1f1f1;
  background-color: transparent;
  background-image: none;
  border-color: #f1f1f1; }
  .btn-outline-gray-200:hover {
    color: #fff;
    background-color: #f1f1f1;
    border-color: #f1f1f1; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #f1f1f1;
    background-color: transparent; }
  .btn-outline-gray-200:not([disabled]):not(.disabled):active, .btn-outline-gray-200:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #fff;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    box-shadow: 0 0 0 0 rgba(241, 241, 241, 0.5); }

.btn-outline-gray-400 {
  color: #cccccc;
  background-color: transparent;
  background-image: none;
  border-color: #cccccc; }
  .btn-outline-gray-400:hover {
    color: #fff;
    background-color: #cccccc;
    border-color: #cccccc; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #cccccc;
    background-color: transparent; }
  .btn-outline-gray-400:not([disabled]):not(.disabled):active, .btn-outline-gray-400:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #fff;
    background-color: #cccccc;
    border-color: #cccccc;
    box-shadow: 0 0 0 0 rgba(204, 204, 204, 0.5); }

.btn-outline-gray-600 {
  color: #999999;
  background-color: transparent;
  background-image: none;
  border-color: #999999; }
  .btn-outline-gray-600:hover {
    color: #fff;
    background-color: #999999;
    border-color: #999999; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #999999;
    background-color: transparent; }
  .btn-outline-gray-600:not([disabled]):not(.disabled):active, .btn-outline-gray-600:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #999999;
    border-color: #999999;
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.btn-outline-gray-700 {
  color: #727272;
  background-color: transparent;
  background-image: none;
  border-color: #727272; }
  .btn-outline-gray-700:hover {
    color: #fff;
    background-color: #727272;
    border-color: #727272; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    box-shadow: 0 0 0 0 rgba(114, 114, 114, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #727272;
    background-color: transparent; }
  .btn-outline-gray-700:not([disabled]):not(.disabled):active, .btn-outline-gray-700:not([disabled]):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #727272;
    border-color: #727272;
    box-shadow: 0 0 0 0 rgba(114, 114, 114, 0.5); }

.btn-link {
  font-weight: 400;
  color: #e7b00e;
  background-color: transparent; }
  .btn-link:hover {
    color: #9f790a;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #999999; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.111;
  border-radius: 0.15rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #ffc72c;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f1f1f1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ffc72c; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #f1f1f1;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #fff;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: 0; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: 0;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .btn-group-vertical > .btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
    border-radius: 0; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap; }

.input-group-addon {
  padding: 1.125rem 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.111;
  color: #727272;
  text-align: center;
  background-color: #f1f1f1;
  border: 0 solid #cccccc;
  border-radius: 0; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: 0; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:first-child > .btn + .btn {
    margin-left: 0; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: 0; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: 0; }
    .input-group-btn:not(:first-child) > .btn:first-child,
    .input-group-btn:not(:first-child) > .btn-group:first-child {
      margin-left: 0; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 1.125rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: transparent; }

.nav-tabs {
  border-bottom: 0 solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #f1f1f1 #f1f1f1 #ddd; }
    .nav-tabs .nav-link.disabled {
      color: transparent;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #727272;
    background-color: transparent;
    border-color: #ddd #ddd transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ffc72c; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1239px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1240px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: #e7b00e; }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #e7b00e; }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: black; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #e7b00e; }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.8);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.8); }
  .navbar-light .navbar-text a {
    color: #e7b00e; }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: #e7b00e; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 2rem; }

.card-title {
  margin-bottom: 2rem; }

.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 2rem; }

.card-header {
  padding: 2rem 2rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0 - 0) calc(0 - 0) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 2rem 2rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0 - 0) calc(0 - 0); }

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -2rem;
  margin-left: -1rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem 2rem; }

.card-img {
  width: 100%;
  border-radius: calc(0 - 0); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 0);
  border-top-right-radius: calc(0 - 0); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 0);
  border-bottom-left-radius: calc(0 - 0); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group .card:first-child .card-img-top {
            border-top-right-radius: 0; }
          .card-group .card:first-child .card-img-bottom {
            border-bottom-right-radius: 0; }
        .card-group .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group .card:last-child .card-img-top {
            border-top-left-radius: 0; }
          .card-group .card:last-child .card-img-bottom {
            border-bottom-left-radius: 0; }
        .card-group .card:only-child {
          border-radius: 0; }
          .card-group .card:only-child .card-img-top {
            border-top-left-radius: 0;
            border-top-right-radius: 0; }
          .card-group .card:only-child .card-img-bottom {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 2rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  color: #999999;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999999; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #ffc72c;
  border-color: #ffc72c; }

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #e7b00e;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #9f790a;
    text-decoration: none;
    background-color: #f1f1f1;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.111; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #111;
  background-color: #ffc72c; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #f8b600; }

.badge-secondary {
  color: #111;
  background-color: #fff; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #e6e5e5; }

.badge-success {
  color: #111;
  background-color: #c4d600; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #95a300; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #111;
  background-color: #ffc72c; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #f8b600; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #111;
  background-color: #f8f9fa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #3d3d3d; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #242323; }

.badge-purple {
  color: #fff;
  background-color: #582c83; }
  .badge-purple[href]:focus, .badge-purple[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #3e1f5d; }

.badge-purple-80 {
  color: #fff;
  background-color: #7a569b; }
  .badge-purple-80[href]:focus, .badge-purple-80[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #60447a; }

.badge-black {
  color: #fff;
  background-color: #000; }
  .badge-black[href]:focus, .badge-black[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: black; }

.badge-grau {
  color: #fff;
  background-color: #6f6f6e; }
  .badge-grau[href]:focus, .badge-grau[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #555555; }

.badge-hellgrau {
  color: #111;
  background-color: #bbbcbc; }
  .badge-hellgrau[href]:focus, .badge-hellgrau[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #a1a3a3; }

.badge-blau {
  color: #fff;
  background-color: #003da5; }
  .badge-blau[href]:focus, .badge-blau[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #002a72; }

.badge-orange {
  color: #fff;
  background-color: #fe5000; }
  .badge-orange[href]:focus, .badge-orange[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #cb4000; }

.badge-gruen {
  color: #111;
  background-color: #c4d600; }
  .badge-gruen[href]:focus, .badge-gruen[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #95a300; }

.badge-tuerkis {
  color: #111;
  background-color: #64ccc9; }
  .badge-tuerkis[href]:focus, .badge-tuerkis[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #3fbebb; }

.badge-gelb {
  color: #111;
  background-color: #ffc72c; }
  .badge-gelb[href]:focus, .badge-gelb[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #f8b600; }

.badge-gray-200 {
  color: #111;
  background-color: #f1f1f1; }
  .badge-gray-200[href]:focus, .badge-gray-200[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #d8d7d7; }

.badge-gray-400 {
  color: #111;
  background-color: #cccccc; }
  .badge-gray-400[href]:focus, .badge-gray-400[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #b3b2b2; }

.badge-gray-600 {
  color: #111;
  background-color: #999999; }
  .badge-gray-600[href]:focus, .badge-gray-600[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #807f7f; }

.badge-gray-700 {
  color: #fff;
  background-color: #727272; }
  .badge-gray-700[href]:focus, .badge-gray-700[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #595858; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f1f1f1;
  border-radius: 0; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #856717;
  background-color: #fff4d5;
  border-color: #ffefc4; }
  .alert-primary hr {
    border-top-color: #ffe8ab; }
  .alert-primary .alert-link {
    color: #5a450f; }

.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #6c6b6b; }

.alert-success {
  color: #666f00;
  background-color: #f3f7cc;
  border-color: #eef4b8; }
  .alert-success hr {
    border-top-color: #e9f1a2; }
  .alert-success .alert-link {
    color: #373c00; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856717;
  background-color: #fff4d5;
  border-color: #ffefc4; }
  .alert-warning hr {
    border-top-color: #ffe8ab; }
  .alert-warning .alert-link {
    color: #5a450f; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #202020;
  background-color: #d8d8d8;
  border-color: #c9c9c9; }
  .alert-dark hr {
    border-top-color: #bcbcbc; }
  .alert-dark .alert-link {
    color: #070606; }

.alert-purple {
  color: #2e1744;
  background-color: #ded5e6;
  border-color: #d0c4dc; }
  .alert-purple hr {
    border-top-color: #c3b4d3; }
  .alert-purple .alert-link {
    color: #140a1e; }

.alert-purple-80 {
  color: #3f2d51;
  background-color: #e4ddeb;
  border-color: #dad0e3; }
  .alert-purple-80 hr {
    border-top-color: #cdc0d9; }
  .alert-purple-80 .alert-link {
    color: #261b30; }

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-black hr {
    border-top-color: #ababab; }
  .alert-black .alert-link {
    color: black; }

.alert-grau {
  color: #3a3a39;
  background-color: #e2e2e2;
  border-color: #d7d7d6; }
  .alert-grau hr {
    border-top-color: #cacac9; }
  .alert-grau .alert-link {
    color: #202020; }

.alert-hellgrau {
  color: #616262;
  background-color: #f1f2f2;
  border-color: #ececec; }
  .alert-hellgrau hr {
    border-top-color: #dfdfdf; }
  .alert-hellgrau .alert-link {
    color: #484848; }

.alert-blau {
  color: #002056;
  background-color: #ccd8ed;
  border-color: #b8c9e6; }
  .alert-blau hr {
    border-top-color: #a5bbdf; }
  .alert-blau .alert-link {
    color: #000d23; }

.alert-orange {
  color: #842a00;
  background-color: #ffdccc;
  border-color: #ffceb8; }
  .alert-orange hr {
    border-top-color: #ffbc9f; }
  .alert-orange .alert-link {
    color: #511a00; }

.alert-gruen {
  color: #666f00;
  background-color: #f3f7cc;
  border-color: #eef4b8; }
  .alert-gruen hr {
    border-top-color: #e9f1a2; }
  .alert-gruen .alert-link {
    color: #373c00; }

.alert-tuerkis {
  color: #346a69;
  background-color: #e0f5f4;
  border-color: #d4f1f0; }
  .alert-tuerkis hr {
    border-top-color: #c1ebe9; }
  .alert-tuerkis .alert-link {
    color: #234847; }

.alert-gelb {
  color: #856717;
  background-color: #fff4d5;
  border-color: #ffefc4; }
  .alert-gelb hr {
    border-top-color: #ffe8ab; }
  .alert-gelb .alert-link {
    color: #5a450f; }

.alert-gray-200 {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-gray-200 hr {
    border-top-color: #eeeeee; }
  .alert-gray-200 .alert-link {
    color: #646363; }

.alert-gray-400 {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1; }
  .alert-gray-400 hr {
    border-top-color: #e4e4e4; }
  .alert-gray-400 .alert-link {
    color: #515050; }

.alert-gray-600 {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2; }
  .alert-gray-600 hr {
    border-top-color: #d5d5d5; }
  .alert-gray-600 .alert-link {
    color: #373636; }

.alert-gray-700 {
  color: #3b3b3b;
  background-color: #e3e3e3;
  border-color: #d8d8d8; }
  .alert-gray-700 hr {
    border-top-color: #cbcbcb; }
  .alert-gray-700 .alert-link {
    color: #222121; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f1f1f1;
  border-radius: 0; }

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #ffc72c; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #727272;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #727272;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #000;
    background-color: #f1f1f1; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #999999;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ffc72c;
    border-color: #ffc72c; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #856717;
  background-color: #ffefc4; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #856717; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #856717;
    background-color: #ffe8ab; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #856717;
    border-color: #856717; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #858585; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #858585;
    background-color: #f2f2f2; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-success {
  color: #666f00;
  background-color: #eef4b8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #666f00; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #666f00;
    background-color: #e9f1a2; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #666f00;
    border-color: #666f00; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856717;
  background-color: #ffefc4; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856717; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856717;
    background-color: #ffe8ab; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856717;
    border-color: #856717; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #202020;
  background-color: #c9c9c9; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #202020; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #202020;
    background-color: #bcbcbc; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #202020;
    border-color: #202020; }

.list-group-item-purple {
  color: #2e1744;
  background-color: #d0c4dc; }

a.list-group-item-purple,
button.list-group-item-purple {
  color: #2e1744; }
  a.list-group-item-purple:focus, a.list-group-item-purple:hover,
  button.list-group-item-purple:focus,
  button.list-group-item-purple:hover {
    color: #2e1744;
    background-color: #c3b4d3; }
  a.list-group-item-purple.active,
  button.list-group-item-purple.active {
    color: #fff;
    background-color: #2e1744;
    border-color: #2e1744; }

.list-group-item-purple-80 {
  color: #3f2d51;
  background-color: #dad0e3; }

a.list-group-item-purple-80,
button.list-group-item-purple-80 {
  color: #3f2d51; }
  a.list-group-item-purple-80:focus, a.list-group-item-purple-80:hover,
  button.list-group-item-purple-80:focus,
  button.list-group-item-purple-80:hover {
    color: #3f2d51;
    background-color: #cdc0d9; }
  a.list-group-item-purple-80.active,
  button.list-group-item-purple-80.active {
    color: #fff;
    background-color: #3f2d51;
    border-color: #3f2d51; }

.list-group-item-black {
  color: black;
  background-color: #b8b8b8; }

a.list-group-item-black,
button.list-group-item-black {
  color: black; }
  a.list-group-item-black:focus, a.list-group-item-black:hover,
  button.list-group-item-black:focus,
  button.list-group-item-black:hover {
    color: black;
    background-color: #ababab; }
  a.list-group-item-black.active,
  button.list-group-item-black.active {
    color: #fff;
    background-color: black;
    border-color: black; }

.list-group-item-grau {
  color: #3a3a39;
  background-color: #d7d7d6; }

a.list-group-item-grau,
button.list-group-item-grau {
  color: #3a3a39; }
  a.list-group-item-grau:focus, a.list-group-item-grau:hover,
  button.list-group-item-grau:focus,
  button.list-group-item-grau:hover {
    color: #3a3a39;
    background-color: #cacac9; }
  a.list-group-item-grau.active,
  button.list-group-item-grau.active {
    color: #fff;
    background-color: #3a3a39;
    border-color: #3a3a39; }

.list-group-item-hellgrau {
  color: #616262;
  background-color: #ececec; }

a.list-group-item-hellgrau,
button.list-group-item-hellgrau {
  color: #616262; }
  a.list-group-item-hellgrau:focus, a.list-group-item-hellgrau:hover,
  button.list-group-item-hellgrau:focus,
  button.list-group-item-hellgrau:hover {
    color: #616262;
    background-color: #dfdfdf; }
  a.list-group-item-hellgrau.active,
  button.list-group-item-hellgrau.active {
    color: #fff;
    background-color: #616262;
    border-color: #616262; }

.list-group-item-blau {
  color: #002056;
  background-color: #b8c9e6; }

a.list-group-item-blau,
button.list-group-item-blau {
  color: #002056; }
  a.list-group-item-blau:focus, a.list-group-item-blau:hover,
  button.list-group-item-blau:focus,
  button.list-group-item-blau:hover {
    color: #002056;
    background-color: #a5bbdf; }
  a.list-group-item-blau.active,
  button.list-group-item-blau.active {
    color: #fff;
    background-color: #002056;
    border-color: #002056; }

.list-group-item-orange {
  color: #842a00;
  background-color: #ffceb8; }

a.list-group-item-orange,
button.list-group-item-orange {
  color: #842a00; }
  a.list-group-item-orange:focus, a.list-group-item-orange:hover,
  button.list-group-item-orange:focus,
  button.list-group-item-orange:hover {
    color: #842a00;
    background-color: #ffbc9f; }
  a.list-group-item-orange.active,
  button.list-group-item-orange.active {
    color: #fff;
    background-color: #842a00;
    border-color: #842a00; }

.list-group-item-gruen {
  color: #666f00;
  background-color: #eef4b8; }

a.list-group-item-gruen,
button.list-group-item-gruen {
  color: #666f00; }
  a.list-group-item-gruen:focus, a.list-group-item-gruen:hover,
  button.list-group-item-gruen:focus,
  button.list-group-item-gruen:hover {
    color: #666f00;
    background-color: #e9f1a2; }
  a.list-group-item-gruen.active,
  button.list-group-item-gruen.active {
    color: #fff;
    background-color: #666f00;
    border-color: #666f00; }

.list-group-item-tuerkis {
  color: #346a69;
  background-color: #d4f1f0; }

a.list-group-item-tuerkis,
button.list-group-item-tuerkis {
  color: #346a69; }
  a.list-group-item-tuerkis:focus, a.list-group-item-tuerkis:hover,
  button.list-group-item-tuerkis:focus,
  button.list-group-item-tuerkis:hover {
    color: #346a69;
    background-color: #c1ebe9; }
  a.list-group-item-tuerkis.active,
  button.list-group-item-tuerkis.active {
    color: #fff;
    background-color: #346a69;
    border-color: #346a69; }

.list-group-item-gelb {
  color: #856717;
  background-color: #ffefc4; }

a.list-group-item-gelb,
button.list-group-item-gelb {
  color: #856717; }
  a.list-group-item-gelb:focus, a.list-group-item-gelb:hover,
  button.list-group-item-gelb:focus,
  button.list-group-item-gelb:hover {
    color: #856717;
    background-color: #ffe8ab; }
  a.list-group-item-gelb.active,
  button.list-group-item-gelb.active {
    color: #fff;
    background-color: #856717;
    border-color: #856717; }

.list-group-item-gray-200 {
  color: #7d7d7d;
  background-color: #fbfbfb; }

a.list-group-item-gray-200,
button.list-group-item-gray-200 {
  color: #7d7d7d; }
  a.list-group-item-gray-200:focus, a.list-group-item-gray-200:hover,
  button.list-group-item-gray-200:focus,
  button.list-group-item-gray-200:hover {
    color: #7d7d7d;
    background-color: #eeeeee; }
  a.list-group-item-gray-200.active,
  button.list-group-item-gray-200.active {
    color: #fff;
    background-color: #7d7d7d;
    border-color: #7d7d7d; }

.list-group-item-gray-400 {
  color: #6a6a6a;
  background-color: #f1f1f1; }

a.list-group-item-gray-400,
button.list-group-item-gray-400 {
  color: #6a6a6a; }
  a.list-group-item-gray-400:focus, a.list-group-item-gray-400:hover,
  button.list-group-item-gray-400:focus,
  button.list-group-item-gray-400:hover {
    color: #6a6a6a;
    background-color: #e4e4e4; }
  a.list-group-item-gray-400.active,
  button.list-group-item-gray-400.active {
    color: #fff;
    background-color: #6a6a6a;
    border-color: #6a6a6a; }

.list-group-item-gray-600 {
  color: #505050;
  background-color: #e2e2e2; }

a.list-group-item-gray-600,
button.list-group-item-gray-600 {
  color: #505050; }
  a.list-group-item-gray-600:focus, a.list-group-item-gray-600:hover,
  button.list-group-item-gray-600:focus,
  button.list-group-item-gray-600:hover {
    color: #505050;
    background-color: #d5d5d5; }
  a.list-group-item-gray-600.active,
  button.list-group-item-gray-600.active {
    color: #fff;
    background-color: #505050;
    border-color: #505050; }

.list-group-item-gray-700 {
  color: #3b3b3b;
  background-color: #d8d8d8; }

a.list-group-item-gray-700,
button.list-group-item-gray-700 {
  color: #3b3b3b; }
  a.list-group-item-gray-700:focus, a.list-group-item-gray-700:hover,
  button.list-group-item-gray-700:focus,
  button.list-group-item-gray-700:hover {
    color: #3b3b3b;
    background-color: #cbcbcb; }
  a.list-group-item-gray-700.active,
  button.list-group-item-gray-700.active {
    color: #fff;
    background-color: #3b3b3b;
    border-color: #3b3b3b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .modal-header .close {
    padding: 15px;
    margin: -15px -15px -15px auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #f1f1f1; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Corbel, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 10px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -8px;
      content: "";
      border-width: 10px 10px 0;
      border-top-color: #fff; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 10px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -8px;
      content: "";
      border-width: 10px 10px 10px 0;
      border-right-color: #fff; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 10px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -8px;
      content: "";
      border-width: 0 10px 10px;
      border-bottom-color: #fff; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 10px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -8px;
      content: "";
      border-width: 10px 0 10px 10px;
      border-left-color: #fff; }

.tooltip-inner {
  max-width: 230px;
  padding: 0.875rem 0.875rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Corbel, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #000;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 0.8rem; }
  .popover .arrow::after {
    content: "";
    border-width: 0.8rem; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.8rem; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -0.8rem;
      margin-left: -0.8rem;
      border-top-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-top .arrow::after,
    .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: calc((0.8rem - 0) * -1);
      margin-left: -0.8rem;
      border-top-color: #000; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.8rem; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -0.8rem;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -0.8rem;
      border-right-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-right .arrow::after,
    .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: calc((0.8rem - 0) * -1);
      border-right-color: #000; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.8rem; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -0.8rem;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -0.8rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-bottom .arrow::after,
    .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: calc((0.8rem - 0) * -1);
      border-bottom-color: #000; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 0 solid black; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.8rem; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -0.8rem;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -0.8rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    
    .popover.bs-popover-left .arrow::after,
    .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: calc((0.8rem - 0) * -1);
      border-left-color: #000; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: black;
  border-bottom: 0 solid black;
  border-top-left-radius: calc(0 - 0);
  border-top-right-radius: calc(0 - 0); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #fff; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ffc72c !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #f8b600 !important; }

.bg-secondary {
  background-color: #fff !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #e6e5e5 !important; }

.bg-success {
  background-color: #c4d600 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #95a300 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc72c !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #f8b600 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3d3d3d !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #242323 !important; }

.bg-purple {
  background-color: #582c83 !important; }

a.bg-purple:focus, a.bg-purple:hover {
  background-color: #3e1f5d !important; }

.bg-purple-80 {
  background-color: #7a569b !important; }

a.bg-purple-80:focus, a.bg-purple-80:hover {
  background-color: #60447a !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:focus, a.bg-black:hover {
  background-color: black !important; }

.bg-grau {
  background-color: #6f6f6e !important; }

a.bg-grau:focus, a.bg-grau:hover {
  background-color: #555555 !important; }

.bg-hellgrau {
  background-color: #bbbcbc !important; }

a.bg-hellgrau:focus, a.bg-hellgrau:hover {
  background-color: #a1a3a3 !important; }

.bg-blau {
  background-color: #003da5 !important; }

a.bg-blau:focus, a.bg-blau:hover {
  background-color: #002a72 !important; }

.bg-orange {
  background-color: #fe5000 !important; }

a.bg-orange:focus, a.bg-orange:hover {
  background-color: #cb4000 !important; }

.bg-gruen {
  background-color: #c4d600 !important; }

a.bg-gruen:focus, a.bg-gruen:hover {
  background-color: #95a300 !important; }

.bg-tuerkis {
  background-color: #64ccc9 !important; }

a.bg-tuerkis:focus, a.bg-tuerkis:hover {
  background-color: #3fbebb !important; }

.bg-gelb {
  background-color: #ffc72c !important; }

a.bg-gelb:focus, a.bg-gelb:hover {
  background-color: #f8b600 !important; }

.bg-gray-200 {
  background-color: #f1f1f1 !important; }

a.bg-gray-200:focus, a.bg-gray-200:hover {
  background-color: #d8d7d7 !important; }

.bg-gray-400 {
  background-color: #cccccc !important; }

a.bg-gray-400:focus, a.bg-gray-400:hover {
  background-color: #b3b2b2 !important; }

.bg-gray-600 {
  background-color: #999999 !important; }

a.bg-gray-600:focus, a.bg-gray-600:hover {
  background-color: #807f7f !important; }

.bg-gray-700 {
  background-color: #727272 !important; }

a.bg-gray-700:focus, a.bg-gray-700:hover {
  background-color: #595858 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #f1f1f1 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ffc72c !important; }

.border-secondary {
  border-color: #fff !important; }

.border-success {
  border-color: #c4d600 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc72c !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3d3d3d !important; }

.border-purple {
  border-color: #582c83 !important; }

.border-purple-80 {
  border-color: #7a569b !important; }

.border-black {
  border-color: #000 !important; }

.border-grau {
  border-color: #6f6f6e !important; }

.border-hellgrau {
  border-color: #bbbcbc !important; }

.border-blau {
  border-color: #003da5 !important; }

.border-orange {
  border-color: #fe5000 !important; }

.border-gruen {
  border-color: #c4d600 !important; }

.border-tuerkis {
  border-color: #64ccc9 !important; }

.border-gelb {
  border-color: #ffc72c !important; }

.border-gray-200 {
  border-color: #f1f1f1 !important; }

.border-gray-400 {
  border-color: #cccccc !important; }

.border-gray-600 {
  border-color: #999999 !important; }

.border-gray-700 {
  border-color: #727272 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1240px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1240px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1240px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1240px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1240px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ffc72c !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #f8b600 !important; }

.text-secondary {
  color: #fff !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #e6e5e5 !important; }

.text-success {
  color: #c4d600 !important; }

a.text-success:focus, a.text-success:hover {
  color: #95a300 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc72c !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #f8b600 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #3d3d3d !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #242323 !important; }

.text-purple {
  color: #582c83 !important; }

a.text-purple:focus, a.text-purple:hover {
  color: #3e1f5d !important; }

.text-purple-80 {
  color: #7a569b !important; }

a.text-purple-80:focus, a.text-purple-80:hover {
  color: #60447a !important; }

.text-black {
  color: #000 !important; }

a.text-black:focus, a.text-black:hover {
  color: black !important; }

.text-grau {
  color: #6f6f6e !important; }

a.text-grau:focus, a.text-grau:hover {
  color: #555555 !important; }

.text-hellgrau {
  color: #bbbcbc !important; }

a.text-hellgrau:focus, a.text-hellgrau:hover {
  color: #a1a3a3 !important; }

.text-blau {
  color: #003da5 !important; }

a.text-blau:focus, a.text-blau:hover {
  color: #002a72 !important; }

.text-orange {
  color: #fe5000 !important; }

a.text-orange:focus, a.text-orange:hover {
  color: #cb4000 !important; }

.text-gruen {
  color: #c4d600 !important; }

a.text-gruen:focus, a.text-gruen:hover {
  color: #95a300 !important; }

.text-tuerkis {
  color: #64ccc9 !important; }

a.text-tuerkis:focus, a.text-tuerkis:hover {
  color: #3fbebb !important; }

.text-gelb {
  color: #ffc72c !important; }

a.text-gelb:focus, a.text-gelb:hover {
  color: #f8b600 !important; }

.text-gray-200 {
  color: #f1f1f1 !important; }

a.text-gray-200:focus, a.text-gray-200:hover {
  color: #d8d7d7 !important; }

.text-gray-400 {
  color: #cccccc !important; }

a.text-gray-400:focus, a.text-gray-400:hover {
  color: #b3b2b2 !important; }

.text-gray-600 {
  color: #999999 !important; }

a.text-gray-600:focus, a.text-gray-600:hover {
  color: #807f7f !important; }

.text-gray-700 {
  color: #727272 !important; }

a.text-gray-700:focus, a.text-gray-700:hover {
  color: #595858 !important; }

.text-muted {
  color: #999999 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.navbar {
  border-bottom: 13px solid #582c83;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.29); }
  @media (min-width: 992px) {
    .navbar:not(.navbar-small) {
      padding-top: 0.25rem; } }
  .navbar * {
    line-height: 1.2;
    transition: all 0.2s ease-in-out; }

.navbar-brand img {
  height: 49px; }
  @media (max-width: 991px) {
    .navbar-brand img {
      height: 28px; } }

nav ul.navbar-nav [class*="mx-"].nav-item:last-child {
  margin-right: 0 !important; }

.nav-link,
.navbar-text {
  font-size: 1.125rem;
  font-weight: 700;
  word-break: break-word;
  word-wrap: break-word; }
  @media (min-width: 992px) and (max-width: 1100px) {
    .nav-link,
    .navbar-text {
      font-size: 1.0625rem; } }
  @media (min-width: 1240px) {
    .nav-link,
    .navbar-text {
      font-size: 1.25rem; } }
  .nav-link.extra,
  .navbar-text.extra {
    padding-right: 0; }
    @media (min-width: 992px) {
      .nav-link.extra,
      .navbar-text.extra {
        padding-left: 30px;
        padding-right: 30px; } }
  @media (max-width: 991px) {
    :not(.show) > .nav-link:not(:last-child), :not(.show) >
    .navbar-text:not(:last-child) {
      border-bottom: 1px solid #999999; }
    .navbar-language .nav-link, .navbar-language
    .navbar-text {
      border-bottom: 0; } }
  @media (min-width: 992px) {
    :not(.navbar-small):not(.navbar-nav) .nav-link, :not(.navbar-small):not(.navbar-nav)
    .navbar-text {
      padding-top: 1rem;
      padding-bottom: 1rem; }
      .navbar-language :not(.navbar-small):not(.navbar-nav) .nav-link, .navbar-language :not(.navbar-small):not(.navbar-nav)
      .navbar-text {
        padding-top: 0;
        padding-bottom: 0; } }
  .active .nav-link,
  .active .nav-link.btn, .active
  .navbar-text,
  .active
  .navbar-text.btn {
    font-style: italic; }

.nav-link.btn,
.navbar-meta > .btn {
  border-radius: 0; }
  .nav-link.btn:focus, .nav-link.btn.focus, .nav-link.btn:active, .nav-link.btn.active,
  .navbar-meta > .btn:focus,
  .navbar-meta > .btn.focus,
  .navbar-meta > .btn:active,
  .navbar-meta > .btn.active {
    box-shadow: none; }

.dropdown-toggle:after,
.subnav-toggle-mobile > *:first-child:after {
  border: 0;
  content: '';
  display: inline-block;
  height: 14px;
  margin-bottom: -.25rem;
  margin-left: 1rem;
  margin-right: 0;
  width: 10px; }
  @media (max-width: 991px) {
    .dropdown-toggle:after,
    .subnav-toggle-mobile > *:first-child:after {
      float: right; } }

.dropdown-menu.main-menu {
  border-bottom: 13px solid #ffc72c;
  border-radius: 0;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 0; }
  @media (min-width: 992px) {
    .dropdown-menu.main-menu {
      margin-left: 0;
      margin-right: 0;
      padding-top: 3.125rem;
      padding-bottom: 3.75rem;
      width: 100%; } }
  @media (max-width: 991px) {
    .dropdown-menu.main-menu {
      padding-top: .5rem; }
      .dropdown-menu.main-menu .container {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%; } }
  .dropdown-menu.main-menu .nav-link,
  .dropdown-menu.main-menu .nav-text {
    color: #fff;
    font-weight: normal; }
    .active .dropdown-menu.main-menu .nav-link,
    .active .dropdown-menu.main-menu .nav-link.btn, .active
    .dropdown-menu.main-menu .nav-text,
    .active
    .dropdown-menu.main-menu .nav-text.btn {
      font-style: normal; }
  @media (min-width: 992px) {
    .dropdown-menu.main-menu .nav .nav-link,
    .dropdown-menu.main-menu .nav .nav-text {
      padding-left: 0;
      padding-right: 2.5rem; } }
  .dropdown-menu.main-menu .nav .nav-link.active, .dropdown-menu.main-menu .nav .nav-link:active, .dropdown-menu.main-menu .nav .nav-link:hover {
    color: #fff;
    text-decoration: underline; }
  @media (min-width: 992px) {
    .dropdown-menu.main-menu .nav .nav-link {
      padding-left: 1.5rem; }
      .dropdown-menu.main-menu .nav .nav-link:before {
        display: inline-block;
        background: svg-load("../_gfx/svg/arrow-right.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
        content: '';
        height: .9375rem;
        line-height: 1;
        margin-right: .5rem;
        vertical-align: middle;
        width: 1.3125rem;
        margin-left: -1.5rem; } }
  @media (min-width: 992px) {
    .dropdown-menu.main-menu .nav:not(:first-child) .nav-text {
      padding-left: 2rem; }
    .dropdown-menu.main-menu .nav:not(:first-child) .nav-link {
      padding-left: 3.5rem; } }
  @media (max-width: 991px) {
    .dropdown-menu.main-menu .nav {
      padding-top: .5rem; }
      .dropdown-menu.main-menu .nav:not(:last-child) {
        border-bottom: 1px solid #fff;
        padding-bottom: .5rem; } }
  @media (min-width: 992px) {
    [class*="col"] .dropdown-menu.main-menu .nav.flex-column:not(:last-child) {
      border-right: 1px solid #fff; } }

.flex-grow-one {
  flex-grow: 1; }

[class*='navbar-expand'] .navbar-nav .extra {
  padding-right: 0; }
  @media (min-width: 992px) {
    [class*='navbar-expand'] .navbar-nav .extra {
      padding-left: 15px; } }
  @media (min-width: 1240px) {
    [class*='navbar-expand'] .navbar-nav .extra {
      padding-left: 30px; } }

@media (max-width: 991px) {
  [class*='navbar-expand'] .navbar-collapse {
    margin-left: -30px;
    margin-right: -30px;
    max-height: calc(100vh - 2*1.3125rem - 28px - 13px - 2px);
    overflow-y: auto; }
    [class*='navbar-expand'] .navbar-collapse > * {
      margin-left: 30px;
      margin-right: 30px; } }

@media (min-width: 768px) {
  .navbar-collapse .button-wrapper .btn.align-self-stretch {
    flex-basis: 40%;
    flex-grow: 0; } }

.subnav-toggle-mobile > *:first-child {
  display: block; }

@media (max-width: 991px) {
  .subnav-toggle-mobile > *:first-child {
    cursor: pointer; }
    .subnav-toggle-mobile > *:first-child:after {
      background: svg-load("../_gfx/svg/arrow-down.svg", fill="#fff", stroke=transparent) no-repeat left top;
      background-size: contain;
      transition: all 0.2s ease-in-out; }
  .subnav-toggle-mobile.show > *:first-child:after {
    transform: rotate(-180deg); }
  .subnav-toggle-mobile .nav-link {
    display: none; }
  .subnav-toggle-mobile.show .nav-link {
    color: #fff;
    display: block; }
  .navbar-light .navbar-nav .subnav-toggle-mobile.show > .nav-link:not(:last-child) {
    border-bottom: 1px solid #999999; } }

.navbar-meta {
  font-size: 1.125rem;
  position: relative;
  transition: height 0.35s ease; }
  @media (max-width: 991px) {
    .navbar-meta {
      margin-top: 1.5rem; } }
  @media (min-width: 992px) and (max-width: 1100px) {
    .navbar-meta {
      font-size: 1.0625rem; } }
  .navbar-meta a {
    color: #999999;
    display: inline-block;
    font-size: inherit;
    line-height: 1.5;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
    @media (min-width: 992px) {
      .navbar-meta a {
        padding-bottom: 0;
        padding-top: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem; }
        .navbar-meta a:hover {
          text-decoration: underline; } }
  .navbar-meta .btn {
    border: 0; }
    @media (max-width: 991px) {
      .navbar-meta .btn {
        padding-right: 0;
        padding-left: 0; } }
  .navbar-meta .dropdown-menu {
    border: 1px solid #f1f1f1;
    margin-bottom: 1rem; }
  .navbar-meta .dropdown-header {
    color: #999999; }

@media (max-width: 991px) {
  .navbar-language {
    margin-top: 1.5rem; } }

@media (min-width: 992px) {
  .navbar-language :not(:first-child).nav-item {
    border-left: 1px solid #999999; }
  .navbar-language .nav-link {
    padding-left: .25rem;
    padding-right: .25rem;
    text-transform: uppercase; }
    .navbar-language .nav-link.active {
      font-style: normal; } }

.navbar-light .navbar-nav .nav-link.extra,
.navbar-light .navbar-nav .navbar-text.extra {
  color: #e7b00e; }

.navbar-light .navbar-nav .subnav-toggle-mobile.active .nav-link {
  color: #fff; }

@media (max-width: 991px) {
  .navbar-light .navbar-nav .subnav-toggle-mobile .nav-link.active,
  .navbar-light .navbar-nav .subnav-toggle-mobile .nav-text.active {
    color: #e7b00e; } }

.navbar-light .navbar-nav .dropdown-toggle:after {
  background: svg-load("../_gfx/svg/arrow-down.svg", fill="#000", stroke=transparent) no-repeat left top;
  background-size: contain; }

.navbar-light .navbar-nav .active > .dropdown-toggle:after,
.navbar-light .navbar-nav .show > .dropdown-toggle:after {
  background: svg-load("../_gfx/svg/arrow-down.svg", fill="#e7b00e", stroke=transparent) no-repeat left top;
  background-size: contain; }

.navbar-light .navbar-nav.navbar-meta, .navbar-light .navbar-nav.navbar-meta a {
  color: #999999;
  font-weight: normal; }
  .navbar-light .navbar-nav.navbar-meta.active, .navbar-light .navbar-nav.navbar-meta a.active {
    font-weight: 700; }

.navbar-light .navbar-nav.navbar-meta .dropdown-toggle:after {
  background: svg-load("../_gfx/svg/arrow-down.svg", fill="#999999", stroke=transparent) no-repeat left top;
  background-size: contain; }

.navbar-light .navbar-nav.navbar-meta .dropdown-toggle[aria-expanded="true"]:after {
  background: svg-load("../_gfx/svg/arrow-down.svg", fill="#999999", stroke=transparent) no-repeat left top;
  background-size: contain; }

.navbar-light .navbar-nav.navbar-meta .dropdown-toggle .active > .dropdown-toggle:after,
.navbar-light .navbar-nav.navbar-meta .dropdown-toggle .show > .dropdown-toggle:after {
  background: svg-load("../_gfx/svg/arrow-down.svg", fill="#000", stroke=transparent) no-repeat left top;
  background-size: contain; }

@media (min-width: 992px) {
  .navbar-small .navbar-brand {
    margin-right: 5.125rem;
    padding-top: 10px; }
    .navbar-small .navbar-brand img {
      height: 35px;
      width: 119px; }
  .navbar-small .navbar-meta {
    display: none;
    height: 0;
    transform: translateY(-200px); }
  .navbar-small .navbar-nav .nav-link.extra {
    padding-right: 15px; } }
  @media (min-width: 992px) and (min-width: 1240px) {
    .navbar-small .navbar-nav .nav-link.extra {
      padding-right: 30px; } }

@media (min-width: 992px) {
  .navbar-small.navbar-light .navbar-nav .nav-link.extra {
    background: #ffc72c;
    color: #fff; } }

.navbar-toggler {
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 26px;
  margin: 5px;
  padding: 0;
  position: relative;
  transform: rotate(0deg);
  width: 26px; }
  .navbar-toggler span {
    background: #999999;
    display: block;
    height: 3px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    width: 100%; }
    .navbar-toggler span:nth-child(1) {
      top: 3px; }
    .navbar-toggler span:nth-child(2), .navbar-toggler span:nth-child(3) {
      top: 12px; }
    .navbar-toggler span:nth-child(4) {
      top: 21px; }
  .navbar-toggler[aria-expanded="true"] span {
    background: #ffc72c; }
    .navbar-toggler[aria-expanded="true"] span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%; }
    .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
      transform: rotate(45deg);
      width: 124%; }
    .navbar-toggler[aria-expanded="true"] span:nth-child(3) {
      transform: rotate(-45deg);
      width: 124%; }
    .navbar-toggler[aria-expanded="true"] span:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%; }

.breadcrumb {
  margin-top: 4rem; }
  .breadcrumb span {
    padding: 0 0.3rem; }
  @media (max-width: 991px) {
    .breadcrumb {
      display: none; } }

.btn-search {
  background-color: transparent;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 90%;
  margin-top: 0;
  padding: 0;
  width: 1.625rem; }

.input-group .form-control {
  border: 1px solid #e5e5e5;
  font-size: 18px;
  font-style: italic;
  line-height: 1.1;
  width: 175px;
  padding: .5rem;
  z-index: auto; }
  @media (min-width: 992px) and (max-width: 1100px) {
    .input-group .form-control {
      font-size: 1.0625rem; } }
  @media (min-width: 992px) {
    .input-group .form-control {
      border: 0;
      padding: 0 .5rem; } }
  .input-group .form-control:focus {
    box-shadow: none; }
  .input-group .form-control.form-control-lg, .input-group .input-group-lg > .form-control,
  .input-group .input-group-lg > .input-group-btn > .form-control.btn {
    border: 0;
    font-size: 1.25rem;
    padding-bottom: 1rem;
    padding-top: 1rem; }
    @media (min-width: 576px) {
      .input-group .form-control.form-control-lg, .input-group .input-group-lg > .form-control,
      .input-group .input-group-lg > .input-group-btn > .form-control.btn {
        font-size: 2.222rem; } }

.input-group-btn > .btn-search {
  background-image: svg-load("../_gfx/svg/search.svg", fill="#adb5bd", stroke=transparent);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 78%;
  font-size: 0rem;
  z-index: 1; }
  .input-group-btn > .btn-search:not([disabled]):not(.disabled):active, .input-group-btn > .btn-search:not([disabled]):not(.disabled).active {
    background-image: svg-load("../_gfx/svg/search.svg", fill="#000", stroke=transparent); }
  .input-group-btn > .btn-search.btn-lg, .btn-group-lg.input-group-btn > .btn-search.btn {
    background-size: 98%;
    width: 2rem; }

@media (min-width: 576px) {
  .navbar-collapse .form-inline .input-group {
    width: 100%; } }

@media (max-width: 991px) {
  .navbar-collapse .input-group .form-control {
    margin-top: 1.5rem; }
  .navbar-collapse .input-group-btn > .btn-search {
    margin-top: 1.5rem; } }

.form-control {
  font-size: 1.125rem; }
  .form-control:focus + .input-group-btn > .btn-search {
    background-image: svg-load("../_gfx/svg/search.svg", fill="#000", stroke=transparent);
    animation: pulse 1.5s linear infinite; }
  .form-control.bg-black:focus + .input-group-btn > .btn-search {
    background-image: svg-load("../_gfx/svg/search.svg", fill="#fff", stroke=transparent); }

.search-form {
  margin-bottom: 2.556rem; }
  .search-form .form-control {
    background: transparent;
    box-shadow: none;
    color: #fff;
    font-weight: 400;
    padding-top: 0;
    padding-bottom: 0; }
    .search-form .form-control:focus {
      background: transparent; }
    .search-form .form-control::placeholder {
      color: rgba(255, 255, 255, 0.6); }

.search-results + .pagination-lg {
  margin-top: 4rem; }

.search-result .highlight {
  color: #ffc72c;
  text-decoration: underline; }

@keyframes pulse {
  30% {
    transform: scale(1.12); }
  70% {
    transform: scale(0.88); } }

@keyframes searching {
  30% {
    transform: rotateZ(10deg); }
  70% {
    transform: rotateZ(-10deg); } }

.search-result:not(.nav-item):not(.page-item) + .search-result:not(.nav-item):not(.page-item) {
  margin-top: 2.5rem; }

.search-result a.h3 {
  display: block;
  margin-bottom: 1.25rem;
  text-decoration: underline; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffc72c;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(255, 199, 44, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fff6df;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #f1f1f1; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #ddd;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc72c; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #ffc72c;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc72c; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(3.361rem + 0);
  padding: 0.375rem 2rem 0.375rem 1rem;
  line-height: 1.111;
  color: #ffc72c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 100'%3E%3Cpath fill='%23ffc72c' d='M70,64.83a3.59,3.59,0,0,0-1-2.53,3.54,3.54,0,0,0-5,0L38.54,87.86V3.54A3.51,3.51,0,0,0,35,0a3.56,3.56,0,0,0-3.57,3.54V87.86L6.07,62.3a3.6,3.6,0,0,0-5,0,3.55,3.55,0,0,0,0,5l31.46,31.6a3.44,3.44,0,0,0,5,0L69,67.34a3.46,3.46,0,0,0,1-2.5Z'/%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 15px 21px;
  border: 2px solid #ffc72c;
  border-radius: 3px;
  appearance: none; }
  .custom-select:focus {
    border-color: #ffc72c;
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(255, 199, 44, 0.5); }
    .custom-select:focus::-ms-value {
      color: #727272;
      background-color: #fff; }
  .custom-select[multiple] {
    height: auto;
    background-image: none; }
  .custom-select:disabled {
    color: #f1f1f1;
    background-color: #3d3d3d; }
  .custom-select::-ms-expand {
    opacity: 0; }
  .custom-select [disabled="disabled"] {
    color: #f1f1f1; }

.custom-select-sm {
  height: calc(1.472125rem + 0);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.formbuilder .form-check > label {
  display: inline-block;
  font-size: 1.25rem;
  margin-bottom: 0;
  margin-left: 1rem; }
  .formbuilder .form-check > label + input[type='radio'],
  .formbuilder .form-check > label + input[type='check'] {
    margin-left: 30px; }

.formbuilder textarea.form-control {
  min-height: 10.625rem; }

.formbuilder .btn[type='submit'] {
  background-color: #ffc72c;
  color: #fff; }
  @media (max-width: 575px) {
    .formbuilder .btn[type='submit'] {
      width: 100%; } }
  @media (min-width: 576px) {
    .formbuilder .btn[type='submit'] {
      width: 75%;
      display: block;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 768px) {
    .formbuilder .btn[type='submit'] {
      min-width: 100%; } }

.formbuilder .help-block {
  display: block;
  text-align: left;
  margin-top: -1rem;
  margin-bottom: 1rem; }

.formbuilder .form-control {
  font-size: 1.125rem;
  font-style: italic; }

.formbuilder label, .formbuilder .label {
  font-size: 1.125rem;
  margin-bottom: 0.1875rem; }

.bg-black .formbuilder .form-control {
  background: rgba(255, 255, 255, 0.4);
  color: #fff; }
  .bg-black .formbuilder .form-control:focus {
    background: white;
    color: #000; }

.bg-black .formbuilder .col-12 .custom-control.custom-checkbox {
  margin-top: 0.5625rem; }

.bg-black .formbuilder .custom-select.is-invalid,
.bg-black .formbuilder .form-control.is-invalid,
.bg-black .formbuilder .was-validated .custom-select:invalid,
.bg-black .formbuilder .was-validated .form-control:invalid {
  background-color: #ffc72c;
  color: #fff; }

.bg-black .formbuilder a {
  color: #e7b00e; }

.bg-black .formbuilder .invalid-feedback {
  display: inline;
  margin-left: 1em;
  color: #e7b00e;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 700; }

.formbuilder .form-group {
  margin-bottom: 1.4375rem; }
  .formbuilder .form-group legend.col-form-label {
    font-size: 1.125rem;
    float: left;
    padding-bottom: 0;
    padding-top: 0.1875rem;
    width: auto; }
    .formbuilder .form-group legend.col-form-label + .custom-control-inline {
      margin-left: 1.5rem; }

.formbuilder .custom-radio:not(:first-child) {
  margin-left: 1.5rem; }

.bg-black .formbuilder-success {
  font-size: 1.6rem; }
  .bg-black .formbuilder-success.alert-success {
    background-color: transparent;
    border-color: transparent;
    color: #fff; }
    .bg-black .formbuilder-success.alert-success.alert {
      padding: 0; }

select.form-control:not([size]):not([multiple]) {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic;
  padding-left: 1.5rem; }

.filter-cards .card-body.text-white select.form-control:not([size]):not([multiple]) {
  background: #3d3d3d svg-load("../_gfx/svg/arrow-down.svg", fill="#fff", stroke=transparent) no-repeat right 1rem center;
  background-size: 15px 21px;
  border-color: #fff;
  color: #fff;
  height: 3.344rem; }

.filter-cards .card-footer.bg-black .btn-secondary.disabled {
  background-color: #3d3d3d;
  color: #000; }

.badge-large {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic; }
  .badge-large a.remove {
    font-style: normal;
    color: #000;
    text-decoration: none; }
    .badge-large a.remove:hover {
      color: #ffc72c; }

.btn:not(.nav-link) {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.125; }

.btn:not([disabled]):not(.disabled).active, .btn:not([disabled]):not(.disabled):active {
  box-shadow: none; }

.btn.btn-more,
.formbuilder .btn[type='submit'] {
  white-space: normal !important;
  word-wrap: break-word; }
  .btn.btn-more:before,
  .formbuilder .btn[type='submit']:before {
    display: inline-block;
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
    content: '';
    height: .9375rem;
    line-height: 1;
    margin-right: .5rem;
    vertical-align: middle;
    width: 1.3125rem; }
  .btn.btn-more[target="_blank"]:before,
  .formbuilder .btn[type='submit'][target="_blank"]:before {
    display: none; }
  .btn.btn-more[target="_blank"]:after,
  .formbuilder .btn[type='submit'][target="_blank"]:after {
    background: svg-load("../_gfx/svg/external.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
    content: '';
    display: inline-block;
    height: 0.9375rem;
    line-height: 1;
    margin-left: .5rem;
    vertical-align: middle;
    width: 0.875rem; }

.btn.btn-go-to {
  white-space: normal !important;
  word-wrap: break-word; }
  .btn.btn-go-to::after {
    display: inline-block;
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
    content: '';
    height: .9375rem;
    line-height: 1;
    margin-left: .5rem;
    vertical-align: middle;
    width: 1.3125rem; }
  .btn.btn-go-to[target="_blank"]::after {
    background: svg-load("../_gfx/svg/external.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
    width: 0.875rem; }

.btn.btn-primary {
  color: #000; }
  .btn.btn-primary.btn-go-to::after {
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#000", stroke=transparent) no-repeat left center/contain; }

.btn.btn-secondary {
  color: #e7b00e; }
  .btn.btn-secondary:before {
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#e7b00e", stroke=transparent) no-repeat left center/contain; }
  .btn.btn-secondary[target="_blank"]:not(.btn-download):before {
    display: none; }
  .btn.btn-secondary[target="_blank"]:not(.btn-download):after {
    background: svg-load("../_gfx/svg/external.svg", fill="#e7b00e", stroke=transparent) no-repeat left center/contain;
    content: '';
    display: inline-block;
    height: 0.9375rem;
    line-height: 1;
    margin-left: .5rem;
    vertical-align: middle;
    width: 0.875rem; }
  .btn.btn-secondary.btn-download, .btn.btn-secondary.btn-download[target="_blank"] {
    white-space: normal !important;
    word-wrap: break-word; }
    .btn.btn-secondary.btn-download:after, .btn.btn-secondary.btn-download[target="_blank"]:after {
      background: svg-load("../_gfx/svg/download.svg", fill="#e7b00e", stroke=transparent) no-repeat center/contain;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 1.1875rem;
      line-height: 1;
      margin-left: .5rem;
      vertical-align: middle;
      width: 0.875rem; }
  .btn.btn-secondary.btn-go-to::after {
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#e7b00e", stroke=transparent) no-repeat left center/contain; }

.filter-cards .card-footer .btn.btn-more:before {
  display: none; }

.filter-cards .card-footer .btn.btn-more[target="_blank"]:after {
  background: svg-load("../_gfx/svg/external.svg", fill="#e7b00e", stroke=transparent) no-repeat left center/contain;
  content: '';
  display: inline-block;
  height: 0.9375rem;
  line-height: 1;
  margin-left: .5rem;
  vertical-align: middle;
  width: 0.875rem; }

.filter-cards .card-footer .btn.btn-more.disabled:after {
  background: svg-load("../_gfx/svg/external.svg", fill="#000", stroke=transparent) no-repeat left center/contain; }

button:focus {
  outline: 0; }

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"]::-webkit-file-upload-button,
button {
  cursor: pointer; }

.shb-footer {
  border-top: 13px solid #582c83; }
  .shb-footer.text-white a {
    color: white; }
  .shb-footer .inner-full {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .shb-footer .brand {
    /*
		img.img-fluid {
			margin-right: 4.5rem;
			width: 139px \9;

			@include media-breakpoint-up(md) {
				width: 158px \9;
			}
		}*/ }
    .shb-footer .brand img {
      height: 49px; }
      @media (max-width: 991px) {
        .shb-footer .brand img {
          height: 28px; } }
  .shb-footer .nav > .nav-item > .nav-link {
    padding-top: 0;
    padding-bottom: 1rem;
    padding-right: .75rem; }
  .shb-footer .nav > .nav-item .nav {
    padding-top: 2rem; }
    .shb-footer .nav > .nav-item .nav span {
      display: inline-block; }
    .shb-footer .nav > .nav-item .nav .nav-item:not(:first-child) .nav-text {
      padding-top: 1.4375rem; }
    .shb-footer .nav > .nav-item .nav .nav-text,
    .shb-footer .nav > .nav-item .nav .nav-link {
      padding-bottom: .4375rem; }
    .shb-footer .nav > .nav-item .nav .nav-text {
      font-weight: 700; }
    .shb-footer .nav > .nav-item .nav .nav-link {
      font-weight: normal;
      padding-top: 0.4375rem; }
  .shb-footer .nav.nav-meta .nav-item {
    padding-bottom: .4375rem; }
    .shb-footer .nav.nav-meta .nav-item:first-child > .nav-link {
      padding-left: 0; }
    .shb-footer .nav.nav-meta .nav-item:last-child > .nav-link {
      border-right: 0;
      padding-right: 0; }
  .shb-footer .nav.nav-meta .nav-link {
    border-right: 1px solid #fff;
    padding-bottom: 0;
    padding-left: .75rem; }
  @media (max-width: 767px) {
    .shb-footer [class*='text-'][class*='-center'] .brand.d-block {
      margin-left: auto;
      margin-right: auto;
      max-width: 139px; }
    .shb-footer [class*='text-'][class*='-center'] > .nav {
      justify-content: center; } }
  @media (min-width: 768px) {
    .shb-footer [class*='text-'][class*='-right'] > .nav {
      justify-content: flex-end; } }
  .shb-footer .nav-link {
    border-bottom: none;
    font-size: 1.125rem;
    line-height: 1.333;
    padding-left: 0; }
    .shb-footer .nav-link:hover {
      text-decoration: underline; }
    .shb-footer .nav-link[target="_blank"]:before {
      display: none; }
    .shb-footer .nav-link[target="_blank"]:after {
      background: svg-load("../_gfx/svg/external.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
      content: '';
      display: inline-block;
      height: 0.9375rem;
      line-height: 1;
      margin-left: .5rem;
      vertical-align: middle;
      width: 0.875rem; }
  .shb-footer .socialIcons li {
    display: inline;
    margin: auto .375rem; }
    .shb-footer .socialIcons li:first-of-type {
      margin-left: 0; }
    .shb-footer .socialIcons li:last-of-type {
      margin-right: 0; }
    .shb-footer .socialIcons li a {
      text-decoration: none; }
      .shb-footer .socialIcons li a img {
        height: 1.75rem;
        width: 1.75rem; }

/* Informationen zu Breakpoints für die Entwicklung */
.dev-breakpoint-information {
  border: 0;
  bottom: 0;
  display: block !important;
  font-size: 1rem;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  transition: opacity ease-in .75s;
  z-index: 2000; }
  .dev-breakpoint-information > div {
    background-color: lime;
    color: #000;
    padding: 3px 10px;
    text-align: center !important;
    /*
		@include media-breakpoint-up(xxl) {
			background-color:rgb(255,0,255);
			color:#fff;
		}*/ }
    @media (min-width: 576px) and (max-width: 767px) {
      .dev-breakpoint-information > div {
        background-color: #009b00;
        color: #fff; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .dev-breakpoint-information > div {
        background-color: yellow;
        color: #000; } }
    @media (min-width: 992px) and (max-width: 1239px) {
      .dev-breakpoint-information > div {
        background-color: #ffb400;
        color: #000; } }
    @media (min-width: 1240px) {
      .dev-breakpoint-information > div {
        background-color: red;
        color: #fff; } }
  .dev-breakpoint-information:hover {
    opacity: 0;
    transition: opacity ease-out .2s; }

::selection {
  background: #ffc72c;
  color: #fff; }

html {
  font-size: 15px; }
  @media (min-width: 992px) {
    html {
      font-size: 16px; } }

body {
  padding-top: 5rem; }
  @media (min-width: 992px) {
    body {
      padding-top: 5.9375rem; } }

.text-primary {
  color: #e7b00e !important; }

h1 small, h1 .small, .h1 small, .h1 .small,
h2 small,
h2 .small, .h2 small, .h2 .small,
h3 small,
h3 .small, .h3 small, .h3 .small {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: .625rem; }

h1:only-child, .h1:only-child,
h2:only-child, .h2:only-child,
h3:only-child, .h3:only-child {
  margin-bottom: 0; }

h1, .h1,
h2, .h2 {
  margin-bottom: 2.1875rem; }
  @media (min-width: 992px) {
    h1, .h1,
    h2, .h2 {
      font-size: 1.875rem; } }
  h1 + h1.font-italic,
  h1 em, .h1 + h1.font-italic,
  .h1 em,
  h2 + h1.font-italic,
  h2 em, .h2 + h1.font-italic,
  .h2 em, h1 + .h1.font-italic, .h1 + .h1.font-italic,
  h2 + .h1.font-italic, .h2 + .h1.font-italic, h1 +
  h2.font-italic, .h1 +
  h2.font-italic,
  h2 +
  h2.font-italic, .h2 +
  h2.font-italic, h1 + .h2.font-italic, .h1 + .h2.font-italic,
  h2 + .h2.font-italic, .h2 + .h2.font-italic {
    font-weight: normal; }

p {
  margin-bottom: 1.5rem; }
  *:not(.lead) > p:last-child {
    margin-bottom: 0; }
  p a {
    font-weight: 700;
    text-decoration: underline; }

ul {
  padding-left: 1rem; }

li:not(.nav-item):not(.page-item) + li:not(.nav-item):not(.page-item) {
  margin-top: .5rem; }

.lead {
  line-height: 1.2; }

b,
strong {
  font-weight: 700; }

select {
  cursor: pointer; }

.dropdown .btn {
  background: #fff;
  color: #7a569b;
  cursor: pointer; }

.dropdown .dropdown-toggle:after {
  background: transparent svg-load("../_gfx/svg/arrow-down.svg", fill="#7a569b", stroke=transparent) no-repeat right center;
  background-size: 15px 21px;
  height: 1.3125rem;
  width: 0.9375rem; }

.dropdown .dropdown-menu {
  right: 0; }

.dropdown-toggle:after {
  transition: all 0.2s ease-in-out; }

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(-180deg); }

.bg-gray-300 {
  background-color: #e5e5e5; }

.bg-gray-400 {
  background-color: #cccccc; }

.bg-gray-700 {
  background-color: #727272; }

.bg-black .dropdown .btn,
.bg-dark .dropdown .btn {
  background: #3d3d3d;
  border: 2px solid #fff;
  color: #fff; }

.bg-black .dropdown .dropdown-toggle:after,
.bg-dark .dropdown .dropdown-toggle:after {
  background: transparent svg-load("../_gfx/svg/arrow-down.svg", fill="#fff", stroke=transparent) no-repeat right center;
  background-size: 15px 21px; }

.bg-black .btn-secondary.disabled,
.bg-dark .btn-secondary.disabled {
  background-color: #3d3d3d;
  color: #000; }

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px; }

@media (min-width: 768px) {
  .header-large .card-title.h1,
  [class*='slick-header-slider'] .card-title.h1 {
    font-size: 2rem; } }

@media (min-width: 992px) {
  .header-large .card-title.h1,
  [class*='slick-header-slider'] .card-title.h1 {
    font-size: 2.25rem; } }

.header-medium > [class*='container'] .inner-full {
  padding-top: 12.75rem;
  padding-bottom: 0; }
  @media (min-width: 768px) {
    .header-medium > [class*='container'] .inner-full {
      padding-top: 26.75rem; } }

[class*='header'] .card-body {
  padding-bottom: 5.5rem; }

[class*='header'] .blockquote {
  padding: 2rem; }
  @media (min-width: 768px) {
    [class*='header'] .blockquote {
      padding: 2.8125rem 3rem; } }

[class*='header'] .blockquote-footer {
  font-size: 1.25rem;
  padding-top: 0.5rem; }

@media (max-width: 575px) {
  .main-content p a {
    word-break: break-word; } }

.blockquote-footer:empty::before {
  display: none; }

body:not(.editmode) .section-overflow-bottom > :last-child .inner-full:not(.editmode) {
  margin-bottom: -6.25rem;
  position: relative;
  z-index: 1; }

body:not(.editmode) .section-overflow-bottom + * {
  padding-top: 4rem;
  position: relative; }

body:not(.editmode) .section-overflow-bottom[class*='header'] > :last-child .inner-full {
  margin-bottom: -6.25rem; }

body:not(.editmode) .section-overflow-bottom[class*='header'] + * {
  padding-top: 6.25rem; }

body:not(.editmode) .section-overflow-bottom[class*='header'] blockquote {
  margin-bottom: 0; }

body:not(.editmode) .section-overflow-bottom > [class*='container'] .inner-full {
  padding-bottom: 0; }

.inner-full {
  padding: 3rem 0;
  width: 100%; }
  @media (min-width: 768px) {
    .inner-full {
      padding: 4.5rem 0 3.75rem; } }
  @media (min-width: 992px) {
    .inner-full {
      padding: 6.125rem 0 4.5rem; } }
  .inner-full:last-child {
    padding-bottom: 4rem; }
    @media (min-width: 768px) {
      .inner-full:last-child {
        padding-bottom: 4.5rem; } }
    @media (min-width: 992px) {
      .inner-full:last-child {
        padding-bottom: 6.125rem; } }
  .inner-full.w-66-lg {
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 991px) {
      .inner-full.w-66-lg {
        padding-left: 15px;
        padding-right: 15px; } }
    @media (min-width: 768px) {
      .inner-full.w-66-lg {
        margin-bottom: 3.75rem;
        padding-bottom: 4rem;
        padding-top: 4rem;
        width: 83.333%; } }
    @media (min-width: 992px) {
      .inner-full.w-66-lg {
        margin-bottom: 4.5rem;
        width: 66.667%; } }

.inner .inner,
.inner-full .inner {
  padding-top: 0;
  padding-bottom: 0; }

.inner .inner-full,
.inner-full .inner-full {
  padding-top: 0;
  padding-bottom: 0; }

.inner + .inner,
.inner-full + .inner,
.inner + .inner-full,
.inner-full + .inner-full:not(.w-66-lg),
section.bg-white + section.bg-white > .container > .inner-full:not(.w-66-lg) {
  padding-top: 0; }

.card-col > a:hover {
  text-decoration: none; }

.card-col .card {
  height: 100%; }
  .card-col .card .card-body figure.text-white figcaption {
    color: inherit; }
  .card-col .card figure.icons {
    margin-top: 0.625rem; }
    .card-col .card figure.icons img {
      margin-bottom: 2.1875rem;
      max-height: 5.3125rem; }
    .card-col .card figure.icons figcaption {
      font-size: 1.467rem;
      line-height: 1.3; }

@media (min-width: 992px) {
  .card-col.col-lg-3 {
    flex: 0 0 33%;
    max-width: 33%; } }

.card {
  overflow: hidden; }
  .card:not(.card-flat) {
    box-shadow: 0 2px 13px rgba(0, 0, 0, 0.23); }
  .card.border-none {
    border-width: 0; }
  .card.card-green .card-header {
    background: #c4d600; }
  .card.card-green .colored {
    color: #c4d600; }
  .card.card-blue .card-header {
    background: #003da5; }
  .card.card-blue .colored {
    color: #003da5; }
  .card.card-orange .card-header {
    background: #fe5000; }
  .card.card-orange .colored {
    color: #fe5000; }
  .card.card-green .card-header, .card.card-blue .card-header, .card.card-orange .card-header {
    border-bottom: 1px solid #fff; }

.card-header {
  background-color: transparent;
  border: 0;
  padding-bottom: 0; }
  .card-header h1, .card-header .h1, .card-header h2, .card-header .h2 {
    margin-bottom: 0; }
  .card-header + .card-body {
    padding-top: 3rem; }

.card-footer {
  border-top: none;
  background: none;
  padding-top: 1rem;
  padding-bottom: 2.5rem; }
  .card-body + .card-footer {
    padding-top: 0; }

.icon-col .card-body {
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 992px) {
    .icon-col .card-body {
      padding-left: 3.4375rem;
      padding-right: 3.4375rem; } }
  @media (min-width: 1240px) {
    .icon-col .card-body {
      padding-left: 5.5625rem;
      padding-right: 5.5625rem; } }

.image-scale a .card {
  overflow: hidden; }
  .image-scale a .card .card-img-top {
    transform: scale(1);
    transition: .4s ease-in-out; }
  .image-scale a .card:hover .card-img-top {
    transition: .3s ease-in-out;
    transform: scale(1.2); }

a .letter-card:hover .btn {
  text-decoration: underline; }

.letter-card .card-img-top {
  display: block;
  padding-top: 11.8631rem; }
  @media (min-width: 992px) {
    .letter-card .card-img-top {
      padding-top: 10.467rem; } }
  @media (min-width: 1240px) {
    .letter-card .card-img-top {
      padding-top: 13.375rem; } }

.letter-card .card-body {
  bottom: 81px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  top: 0;
  width: 100%; }
  @media (min-width: 768px) {
    .letter-card .card-body {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 992px) {
    .letter-card .card-body {
      bottom: 106px; } }

.letter-card .card-footer {
  align-items: center;
  display: flex;
  height: 81px;
  padding: 0;
  position: relative;
  z-index: 1; }
  @media (min-width: 992px) {
    .letter-card .card-footer {
      height: 106px; } }
  .letter-card .card-footer > .btn {
    align-self: center;
    flex: 0 1 100%;
    padding: .5rem;
    padding-left: 4.3rem;
    text-align: left; }
    @media (min-width: 992px) {
      .letter-card .card-footer > .btn {
        padding-left: 5.425rem; } }
  .letter-card .card-footer .btn-link {
    color: inherit;
    font-size: 1.4rem;
    font-weight: normal; }
    @media (min-width: 768px) {
      .letter-card .card-footer .btn-link {
        font-size: 1.733rem; } }
    @media (min-width: 992px) {
      .letter-card .card-footer .btn-link {
        font-size: 2rem; } }
  .letter-card .card-footer .btn-more {
    text-indent: -2.3rem; }
    .letter-card .card-footer .btn-more:before {
      background: svg-load("../_gfx/svg/arrow-right.svg", fill="#e7b00e", stroke=transparent) no-repeat left center/contain;
      height: 1.25rem;
      margin-left: 0;
      vertical-align: baseline;
      width: 1.6875rem; }

.letter {
  background-color: transparent;
  color: rgba(255, 199, 44, 0.95);
  font-size: 15.75rem;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  margin-top: -6.625rem;
  mix-blend-mode: multiply; }
  @media (min-width: 992px) {
    .letter {
      margin-top: -7.8125rem; } }
  @media (min-width: 1240px) {
    .letter {
      font-size: 18.5rem; } }
  .letter.letter-smaller {
    font-size: 210px;
    margin-top: -6.8rem; }
    @media (min-width: 1240px) {
      .letter.letter-smaller {
        font-size: 15.75rem; } }

.filter-cards {
  padding-bottom: 2.75rem; }
  @media (min-width: 768px) {
    .filter-cards {
      padding-bottom: 3.5rem; } }
  @media (min-width: 992px) {
    .filter-cards {
      padding-bottom: 5.125rem; } }
  .inner-full + .filter-cards {
    margin-top: -1.5rem; }
  .filter-cards .filters {
    margin-bottom: 2rem; }
    .filter-cards .filters .dropdown .btn {
      border: 2px solid #ffc72c;
      padding-top: calc(1.0625rem - 2px);
      padding-bottom: calc(1.0625rem - 2px); }
    .filter-cards .filters .dropdown .dropdown-toggle[aria-expanded="true"]:after {
      background: transparent svg-load("../_gfx/svg/close.svg", fill="#7a569b", stroke=transparent) no-repeat right center;
      background-size: contain;
      height: .75rem;
      width: .75rem; }
    @media (max-width: 991px) {
      .filter-cards .filters .dropdown, .filter-cards .filters .form-group {
        margin-bottom: 1rem; } }
    .filter-cards .filters .dropdown-menu {
      right: 30px; }
  .filter-cards .card {
    overflow: visible; }
  .filter-cards .card-header {
    min-height: 4.75rem;
    padding: .875rem  1.875rem; }
  .filter-cards .card-body {
    padding: 1.625rem 1.875rem 0.625rem; }
    .filter-cards .card-body div span {
      display: block;
      line-height: 1.25; }
      .filter-cards .card-body div span + em {
        font-size: 1.125rem;
        line-height: 1.111; }
    .filter-cards .card-body div + div {
      margin-top: .5rem; }
      .filter-cards .card-body div + div:last-child {
        margin-bottom: 1.75rem; }
    .filter-cards .card-body div.form-group:last-child, .filter-cards .card-body div.dropdown:last-child {
      margin-top: 2rem;
      margin-bottom: 0; }
    .filter-cards .card-body em {
      font-weight: bold; }

.pagination {
  margin-top: 3rem; }

.accordion .inner-full,
.accordion .inner-full:last-child {
  padding: 2rem; }

.accordion .card:last-of-type {
  margin-bottom: 2rem; }

.accordion .card,
.accordion .card:not(.card-flat) {
  background: transparent;
  box-shadow: none; }
  .accordion .card .card-header,
  .accordion .card:not(.card-flat) .card-header {
    padding: 2px 0 0; }
    .accordion .card .card-header a,
    .accordion .card:not(.card-flat) .card-header a {
      color: #fff;
      background: #000;
      padding: 0 0 0 2rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: flex-start;
      align-items: center; }
      .accordion .card .card-header a span,
      .accordion .card:not(.card-flat) .card-header a span {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        white-space: normal !important;
        word-wrap: break-word;
        padding: 1rem 1rem 1rem 0; }
      .accordion .card .card-header a i,
      .accordion .card:not(.card-flat) .card-header a i {
        background: #ffc72c svg-load("../_gfx/svg/minus.svg", fill="#fff", stroke=transparent) no-repeat center/contain;
        background-size: 42%;
        min-height: 54px;
        min-width: 54px;
        order: 0;
        flex: 0 1 auto;
        align-self: stretch; }
        @media (min-width: 768px) {
          .accordion .card .card-header a i,
          .accordion .card:not(.card-flat) .card-header a i {
            min-height: 108px;
            min-width: 108px; } }
      .accordion .card .card-header a.collapsed i,
      .accordion .card:not(.card-flat) .card-header a.collapsed i {
        background: #ffc72c svg-load("../_gfx/svg/plus.svg", fill="#fff", stroke=transparent) no-repeat center/contain;
        background-size: 42%; }

header.carousel {
  position: relative; }

.slick-track {
  z-index: 1; }
  .slick-track figure {
    margin-bottom: 0; }

.slick-dotted .slick-dots {
  display: block;
  list-style: none;
  margin-bottom: 0;
  margin-top: 1.375rem;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1; }
  .slick-dotted .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 1.375rem;
    line-height: 1;
    margin: 0 4px;
    padding: 0;
    position: relative;
    width: 1.375rem; }
    .slick-dotted .slick-dots li:before {
      background: #cccccc;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 0.5rem;
      margin-top: auto;
      position: static;
      vertical-align: middle;
      width: 0.5rem; }
    .slick-dotted .slick-dots li.slick-active:before {
      background-color: #ffc72c;
      height: 1.4375rem;
      width: 1.4375rem; }
    .slick-dotted .slick-dots li button {
      display: none; }

.slick-dotted[class*='slick-header-slider'] .slick-dots {
  display: none !important; }
  @media (min-width: 768px) {
    .slick-dotted[class*='slick-header-slider'] .slick-dots {
      bottom: .5rem;
      display: block !important;
      position: absolute; } }
  .slick-dotted[class*='slick-header-slider'] .slick-dots li:before {
    background: #fff; }
  .slick-dotted[class*='slick-header-slider'] .slick-dots li.slick-active:before {
    background-color: #ffc72c; }

.slick-prev,
.slick-next {
  background: #000;
  border: 0;
  cursor: pointer;
  display: none !important;
  font-size: 0;
  height: 42px;
  line-height: 0;
  margin-top: -3.53125rem;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 40%;
  width: 32px;
  z-index: 1; }
  @media (min-width: 576px) {
    .slick-prev,
    .slick-next {
      display: block !important;
      height: 7.0625rem;
      margin-top: -3.53125rem;
      width: 4.374975rem; } }
  .slick-prev:before,
  .slick-next:before {
    content: '';
    display: inline-block;
    height: 11px;
    width: 15px; }
    @media (min-width: 576px) {
      .slick-prev:before,
      .slick-next:before {
        height: 1.1875rem;
        width: 1.624975rem; } }
  [class*='slick-header-slider'] .slick-prev, [class*='slick-header-slider']
  .slick-next {
    display: block !important;
    opacity: .2;
    top: -11vw; }
    @media (min-width: 576px) {
      [class*='slick-header-slider'] .slick-prev, [class*='slick-header-slider']
      .slick-next {
        top: -20vw; } }
    @media (min-width: 768px) {
      [class*='slick-header-slider'] .slick-prev, [class*='slick-header-slider']
      .slick-next {
        top: 50%; } }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 1; }
  .slick-prev:focus,
  .slick-next:focus {
    outline: 0; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    cursor: default;
    opacity: .15; }

.slick-prev {
  left: -2.9375rem; }
  @media (min-width: 576px) and (max-width: 767px) {
    .slick-prev {
      left: -1.4375rem; } }
  .slick-prev:before {
    background: svg-load("../_gfx/svg/arrow-left.svg", fill="#e7b00e", stroke= "transparent") no-repeat;
    background-size: contain; }

.slick-next {
  right: -2.9375rem; }
  @media (min-width: 576px) and (max-width: 767px) {
    .slick-next {
      right: -1.4375rem; } }
  .slick-next:before {
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#e7b00e", stroke= "transparent") no-repeat;
    background-size: contain; }

[class*='slick-header-slider'] {
  width: 100%; }
  [class*='slick-header-slider'] .slick-prev {
    left: 0; }
  [class*='slick-header-slider'] .slick-next {
    right: 0; }
  [class*='slick-header-slider'] .slick-slide .container {
    display: flex; }

@media (min-width: 768px) {
  .slick-slider.slick-header-slider-bg {
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0; } }

.slick-slider .figure-caption {
  font-style: italic;
  text-align: center; }
  @media (min-width: 992px) {
    .slick-slider .figure-caption {
      max-width: 74%; } }

.slick-header-slider-bg > *,
.slick-header-slider-bg > * > *,
.slick-header-slider-bg .slick-slide > div {
  height: 100%; }
  .slick-header-slider-bg > * div[style*="background-image"],
  .slick-header-slider-bg > * > * div[style*="background-image"],
  .slick-header-slider-bg .slick-slide > div div[style*="background-image"] {
    bottom: 0;
    height: 100%;
    position: absolute;
    top: 0; }

.slick-header-slider-bg .slick-slide {
  min-height: 45vw;
  position: relative; }

.slick-header-slider-bg + .slick-header-slider-fg {
  margin-top: -2rem; }
  .slick-header-slider-bg + .slick-header-slider-fg .container {
    min-height: 45vw; }
  .slick-header-slider-bg + .slick-header-slider-fg .inner-full {
    padding-top: 0;
    padding-bottom: 0; }
    @media (min-width: 768px) {
      .slick-header-slider-bg + .slick-header-slider-fg .inner-full {
        padding-top: 180px;
        padding-bottom: 100px; } }

.open-photoswipe {
  display: block;
  position: relative; }
  .open-photoswipe img {
    filter: brightness(1);
    transition: all 0.2s ease-in-out; }
  .open-photoswipe:hover {
    transition: all 0.2s ease-in-out; }
    .open-photoswipe:hover img {
      filter: brightness(0.5); }
    .open-photoswipe:hover:after {
      opacity: 1; }
  .open-photoswipe:after {
    background: #ffc72c svg-load("../_gfx/svg/zoom-plus.svg", fill="#fff", stroke=transparent) no-repeat center/contain;
    background-size: 38%;
    content: '';
    display: inline-block;
    height: 3.75rem;
    line-height: 1;
    margin: -1.875rem 0 0 -1.875rem;
    position: absolute;
    left: 50%;
    opacity: 0;
    vertical-align: middle;
    top: 50%;
    transition: opacity .4s linear;
    width: 3.75rem; }

.listed-item {
  align-content: flex-start;
  align-items: center;
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1.125rem;
  font-weight: 700;
  justify-content: space-between;
  padding: 0 0 0 2rem; }
  .listed-item.listed-news, .listed-item.listed-event {
    justify-content: flex-start;
    padding: 0 3rem 0 0; }
    @media (min-width: 576px) {
      .listed-item.listed-news, .listed-item.listed-event {
        padding: 0 6rem 0 0; } }
    .listed-item.listed-news span, .listed-item.listed-event span {
      display: block;
      margin-left: 2rem; }
  .listed-item small {
    font-size: 77.8%;
    font-weight: normal; }
  .listed-item span {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    white-space: normal !important;
    word-wrap: break-word;
    padding: 1rem 1rem 1rem 0; }
  .listed-item i {
    align-self: stretch;
    flex: 0 1 auto;
    min-height: 54px;
    min-width: 54px;
    order: 0;
    overflow: hidden;
    position: relative; }
    .listed-item i:not(.bg-image):before {
      background: #ffc72c svg-load("../_gfx/svg/download.svg", fill="#fff", stroke=transparent) no-repeat center/contain;
      background-size: 26%;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      transition: all 0.2s ease-in-out;
      width: 100%;
      position: absolute; }
    @media (min-width: 768px) {
      .listed-item i {
        min-height: 74px;
        min-width: 74px; } }
  .listed-item i.bg-image {
    background-size: cover;
    background-position: center center; }
  .listed-item.listed-news.more:before, .listed-item.listed-event.more:before {
    display: inline-block;
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#fff", stroke=transparent) no-repeat left center/contain;
    content: '';
    height: .9375rem;
    line-height: 1;
    margin-right: .5rem;
    vertical-align: middle;
    width: 1.3125rem;
    position: absolute;
    right: 2rem; }
    @media (min-width: 576px) {
      .listed-item.listed-news.more:before, .listed-item.listed-event.more:before {
        right: 3rem; } }
  .listed-item.listed-news i, .listed-item.listed-event i {
    min-height: 108px;
    min-width: 108px; }
    @media (min-width: 992px) {
      .listed-item.listed-news i, .listed-item.listed-event i {
        min-height: 130px;
        min-width: 130px; } }
    @media (min-width: 1240px) {
      .listed-item.listed-news i, .listed-item.listed-event i {
        min-height: 172px;
        min-width: 172px; } }
  .listed-item.listed-news i:not(.bg-image):before {
    background: #ffc72c svg-load("../_gfx/svg/newspaper.svg", fill="#fff", stroke=transparent) no-repeat center;
    background-size: 37%; }
  .listed-item.listed-event i:not(.bg-image):before {
    background: #ffc72c svg-load("../_gfx/svg/clock.svg", fill="#fff", stroke=transparent) no-repeat center;
    background-size: 37%; }
  .listed-itema:hover {
    color: #fff;
    text-decoration: underline; }
    .listed-itema:hover i:before {
      transform: scale(1.2); }
  .listed-item + .listed-item {
    margin-top: 1.25rem; }

@media (max-width: 991px) {
  .col-lg-5.mr-lg-0 + .col-lg-5.ml-lg-0 .listed-item:first-child {
    margin-top: 1.25rem; } }

.shb-section-location-map {
  overflow: hidden; }

.location-marker {
  background: #ffc72c;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 0px transparent;
  color: transparent;
  cursor: pointer;
  height: 1.4375rem;
  margin-left: calc(-1.4375rem/2);
  margin-top: calc(-1.4375rem/2);
  text-align: center;
  transition: all .2s ease;
  width: 1.4375rem; }
  .location-marker:hover, .location-marker[aria-describedby] {
    box-shadow: 0px 0px 0px 1rem rgba(255, 199, 44, 0.5), 0px 0px 0px 3.125rem rgba(255, 199, 44, 0.3), 0px 0px 0px 6.25rem rgba(255, 199, 44, 0.15); }

.popover {
  z-index: 1029; }

.popover.bs-popover-auto[x-placement^=top] .arrow:before,
.popover.bs-popover-auto[x-placement^=top] .arrow:after,
.popover.bs-popover-auto[x-placement^=bottom] .arrow:after,
.popover.bs-popover-auto[x-placement^=bottom] .arrow:before,
.popover.bs-popover-top .arrow:before,
.popover.bs-popover-auto[x-placement^="top"] .arrow:before,
.popover.bs-popover-top .arrow:after,
.popover.bs-popover-auto[x-placement^="top"] .arrow:after,
.popover.bs-popover-bottom .arrow:after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow:after,
.popover.bs-popover-bottom .arrow:before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow:before {
  margin-left: -0.4rem; }

.editmode .x-btn {
  z-index: 1; }

@media (min-width: 576px) {
  .position-sm-absolute {
    position: absolute !important; } }

@media (min-width: 576px) {
  .position-md-absolute {
    position: absolute !important; } }

@media (min-width: 576px) {
  .position-lg-absolute {
    position: absolute !important; } }

.left-minus-1-col[class*='-absolute'] {
  left: -8.333%; }

@media (min-width: 576px) {
  .left-sm-minus-1-col[class*='-absolute'] {
    left: -8.333%; } }

@media (min-width: 768px) {
  .left-md-minus-1-col[class*='-absolute'] {
    left: -8.333%; } }

@media (min-width: 992px) {
  .left-lg-minus-1-col[class*='-absolute'] {
    left: -8.333%; } }

@media (min-width: 1240px) {
  .left-xl-minus-1-col[class*='-absolute'] {
    left: -8.333%; } }

@media (min-width: 576px) {
  [class*='-minus-1-col'] {
    margin-left: -15px; } }

.top-0[class*='-absolute'] {
  top: 0; }

@media (min-width: 576px) {
  .top-sm-0[class*='-absolute'] {
    top: 0; } }

@media (min-width: 768px) {
  .top-md-0[class*='-absolute'] {
    top: 0; } }

@media (min-width: 992px) {
  .top-lg-0[class*='-absolute'] {
    top: 0; } }

.translate-minus-grid-gutter {
  transform: translate(0, -30px); }
  @media (min-width: 576px) {
    .translate-minus-grid-gutter {
      transform: translate(-30px, -30px); } }

@media (min-width: 576px) {
  .pl-sm-2-col {
    padding-left: 33.333% !important; } }

@media (min-width: 768px) {
  .pl-md-2-col {
    padding-left: 33.333% !important; } }

@media (min-width: 992px) {
  .pl-lg-2-col {
    padding-left: 33.333% !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; } }

@media (min-width: 1240px) {
  .float-xl-left {
    float: left !important; } }

.phoneicon:before,
.mailicon:before,
.faxicon:before {
  content: '';
  display: inline-block;
  height: 1.111rem;
  margin-right: 0.611rem;
  padding-left: 1.111rem;
  vertical-align: middle;
  width: 1.111rem; }

.phoneicon:before {
  background: svg-load("../_gfx/svg/phone.svg", fill="#ffc72c", stroke=transparent) no-repeat;
  background-size: 100%; }

.faxicon:before {
  background: svg-load("../_gfx/svg/fax.svg", fill="#ffc72c", stroke=transparent) no-repeat;
  background-size: contain; }

.mailicon {
  padding-left: 1.722rem;
  position: relative;
  white-space: normal !important;
  word-break: break-all;
  word-wrap: break-word; }
  .mailicon:before {
    background: svg-load("../_gfx/svg/mail.svg", fill=#ffc72c) no-repeat;
    background-size: 100%;
    left: 0;
    position: absolute;
    top: 0.133rem; }

.kurzinfos > .row {
  margin-bottom: 3rem; }
  .kurzinfos > .row h3, .kurzinfos > .row p, .kurzinfos > .row ul {
    margin-bottom: 0.5rem; }
  .kurzinfos > .row li {
    margin-top: 0.1rem; }
  .kurzinfos > .row .icon-col .card-body {
    padding-left: 3.4375rem;
    padding-right: 3.4375rem; }

.dropdown-menu.main-menu .nav-link,
.dropdown-menu.main-menu .nav-text {
  color: #000; }

.dropdown-menu.main-menu .nav:not(:last-child) {
  border-bottom-color: #000; }

.dropdown-menu.main-menu .nav .nav-link.active, .dropdown-menu.main-menu .nav .nav-link:active, .dropdown-menu.main-menu .nav .nav-link:hover {
  color: #000; }

@media (min-width: 992px) {
  .dropdown-menu.main-menu .nav .nav-link:before {
    background: svg-load("../_gfx/svg/arrow-right.svg", fill="#000", stroke=transparent) no-repeat left center/contain; } }

[class*="col"] .dropdown-menu.main-menu .nav.flex-column:not(:last-child) {
  border-right-color: #000; }

@media (max-width: 991px) {
  .subnav-toggle-mobile > *:first-child:after {
    background: svg-load("../_gfx/svg/arrow-down.svg", fill="#000", stroke=transparent) no-repeat left top;
    background-size: contain; } }

.navbar-light .navbar-nav .subnav-toggle-mobile.show > .nav-link {
  color: #000;
  /*
		@include media-breakpoint-down(md) {  //  wg. expand-lg <------------------------------------------------------
			border-bottom: 1px solid $gray-600;
		}*/ }

.bg-black a,
.bg-black .formbuilder a {
  color: #ffc72c; }

.bg-black .text-primary {
  color: #ffc72c !important; }

.btn {
  /*
	.filter-cards .card-footer &.btn-more {
		&[target="_blank"] {
			&:after {
				background: svg-load('../_gfx/svg/external.svg', fill='#e7b00e', stroke=transparent) no-repeat left center / contain;
			}
		}

		&.disabled:after {background: svg-load('../_gfx/svg/external.svg', fill='#000', stroke=transparent) no-repeat left center / contain;}
	}*/ }
  .btn.btn-more,
  .formbuilder .btn[type='submit'] {
    color: #000; }
    .btn.btn-more:before,
    .formbuilder .btn[type='submit']:before {
      background: svg-load("../_gfx/svg/arrow-right.svg", fill="#000", stroke=transparent) no-repeat left center/contain; }
    .btn.btn-more[target="_blank"]:after,
    .formbuilder .btn[type='submit'][target="_blank"]:after {
      background: svg-load("../_gfx/svg/external.svg", fill="#000", stroke=transparent) no-repeat left center/contain; }
  .btn.btn-secondary {
    color: #e7b00e; }
    .btn.btn-secondary:before, .btn.btn-secondary.btn-more:before {
      background: svg-load("../_gfx/svg/arrow-right.svg", fill="#ffc72c", stroke=transparent) no-repeat left center/contain; }
    .btn.btn-secondary[target="_blank"]:after {
      background: svg-load("../_gfx/svg/external.svg", fill="#ffc72c", stroke=transparent) no-repeat left center/contain; }

.custom-select {
  border: 2px solid #000;
  color: #000; }

.letter-card .card-footer .btn-more:before {
  background: svg-load("../_gfx/svg/arrow-right.svg", fill="#ffc72c", stroke=transparent) no-repeat left center/contain; }
